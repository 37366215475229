import React, {Component} from 'react';
import FixedScroll from './fixed_scroll';
import TextSearch from './text_search_refactored';
import OpenAISummary from './openai_summary';
import ReferenceWorkflowAI from './reference_workflow_ai';
import Loading from './loading';
import request from '../request';
import Markdown from './markdown';

export default class PopulationDetails extends FixedScroll {
  constructor(props) {
    super(props);

    this.tabs = buildTabs(props);
    this.defaultTab = this.tabs[0].id;
    this.state = {}

    this.onContentLoaded = this.onContentLoaded.bind(this);
  }

  componentDidMount() {
    this.resetScroll(() => {
      if (this.state.tab !== 'reference') {
        this.initializeScrollWatcher();
      }
    });
  }

  resetScroll(callback) {
    let {population_id} = this.props;
    let scroll_population_id = sessionStorage.getItem("current_scroll_population_id");
    if (scroll_population_id && +scroll_population_id == population_id) {
      // set the current tab if it is available
      let tab = sessionStorage.getItem("current_tab");
      if (this.tabs.findIndex(t => t.id === tab) !== -1) {
        this.setState({tab}, callback);
        return false;
      }
    }

    sessionStorage.setItem("current_scroll_population_id", population_id);
    sessionStorage.setItem("current_tab", this.defaultTab);
    sessionStorage.setItem("current_scroll", 0);
    this.setState({tab: this.defaultTab}, callback);
    return true;
  }

  onContentLoaded() {
    this.initializeScrollWatcher();
  }

  changeTab(e, tab) {
    e.preventDefault();
    this.setState({tab});
    sessionStorage.setItem("current_tab", tab);
  }


  renderEntryCriteria() {
    let {text_search_params} = this.props;
    return (
      <div>
        <TextSearch {...text_search_params} />
      </div>
    )
  }

  renderReference() {
    let {reference_id} = this.props;
    return (
      <div className="reference_sidebar_content">
        <ReferenceAbstract reference_id={reference_id} />
        <ReferenceWorkflowAI reference_id={reference_id} />
      </div>
    )
  }

  renderTextSummary() {
    let {trial_id, population_id} = this.props;
    return <PopulationSummary trial_id={trial_id} population_id={population_id} />
  }

  renderAiNotes() {
    let {trial_id, population_id} = this.props;
    return <AiNotes trial_id={trial_id} population_id={population_id} />
  }

  renderContent() {
    let {tab} = this.state;
    if (tab === 'reference') {
      return this.renderReference();
    } else if (tab === 'text_summary') {
      return this.renderTextSummary();
    } else if (tab === 'data_summary') {
      return this.renderEntryCriteria();
    } else if (tab === 'ai_notes') {
      return this.renderAiNotes();
    } else {
      return null;
    }
  }

  renderTabs() {
    let {tab} = this.state;
    return this.tabs.map(({id, label}) => {
      let active = tab === id;
      let className = active ? 'active' : ''
      return  <li key={id} role="presentation" className={className}><a id={id} href="#" onClick={e => this.changeTab(e, id)}>{label}</a></li>
    });
  }

  render() {
    let {updates} = this.props;
    return (
      <div>
        <ul className="nav nav-tabs">
          {this.renderTabs()}
        </ul>
        {this.renderContent()}
      </div>
    )
  }
}


class ReferenceAbstract extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  componentDidMount() {
    this.fetchAISummary();
  }

  fetchAISummary() {
    let {reference_id} = this.props;
    let url = `/admin/references/${reference_id}/text_summary`;
    request('GET', url).then(({text_summary}) => {
      this.setState({text_summary, loading: false})
    }).catch(error => {
      this.setState({loading: false});
    });
  }

  render() {
    let {text_summary, loading} = this.state;
    if (loading) return null;
    return (
      <div>
        <h4><b>Abstract</b></h4>
        <Markdown text={text_summary} />
      </div>
    );
  }
}

class PopulationSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {loading: true};

    this.toggleAlgoritmicSummary = this.toggleAlgoritmicSummary.bind(this);
    this.onChangeOpenaiSummary = this.onChangeOpenaiSummary.bind(this);
    this.onOpenaiSummaryError = this.onOpenaiSummaryError.bind(this);
    this.onChangeOpenaiLoading = this.onChangeOpenaiLoading.bind(this);
  }

  componentDidMount() {
    this.fetchAISummary();
  }

  fetchAISummary() {
    let {trial_id, population_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations/${population_id}/text_summary`;
    request('GET', url).then(({text_summary, ai_summary}) => {
      this.setState({text_summary, ai_summary, loading: false})
    }).catch(error => {
      this.setState({loading: false});
    });
  }

  toggleAlgoritmicSummary() {
    let show_algoritmic_summary = !this.state.show_algoritmic_summary;
    this.setState({show_algoritmic_summary});
  }


  onChangeOpenaiLoading(loading) {
    let {ai_summary} = this.state;
    ai_summary.requesting_summary = loading;
    this.setState({ai_summary});
  }

  onChangeOpenaiSummary(summary) {
    let {ai_summary} = this.state;
    ai_summary.openai_summary = summary;
    ai_summary.update_required = false;
    this.setState({ai_summary});
  }

  onOpenaiSummaryError(message, code) {
    let {ai_summary} = this.state;
    ai_summary.openai_summary_error_message = message;
    ai_summary.openai_summary_error_code = code;
    this.setState({ai_summary});
  }


  renderUpdateRequired() {
    let {ai_summary} = this.state;
    if (!ai_summary || !ai_summary.update_required) return null;
    return (
      <p>
        <strong><span className="fa-solid fa-triangle-exclamation red"></span> Population data has changed. Please generate new summary.</strong>
      </p>
    )
  }

  renderAlgoritmicSummary() {
    let {show_algoritmic_summary, text_summary} = this.state;
    let content, buttonLabel;
    if (show_algoritmic_summary) {
      content =  <pre className="formatted styled_summary">{text_summary}</pre>
      buttonLabel = 'Hide Data Summary'
    } else {
      buttonLabel = 'Show Data Summary'
    }

    let button
    return (
      <div>
        <button className="btn btn-default btn-sm" onClick={this.toggleAlgoritmicSummary}>{buttonLabel}</button>
        {content}
      </div>
    )
  }


  render() {
    let {trial_id, population_id} = this.props;
    let {ai_summary, loading} = this.state;
    if (loading) return <Loading />
    if (!ai_summary) return null;

    let url = `/admin/trials/${trial_id}/populations/${population_id}/openai_summary`

    return (
      <div>
        <div id="ai_summary">
          {this.renderUpdateRequired()}
          <OpenAISummary url={url} data={ai_summary} onChange={this.onChangeOpenaiSummary} onError={this.onOpenaiSummaryError} onChangeLoading={this.onChangeOpenaiLoading} />
        </div>

        {this.renderAlgoritmicSummary()}
      </div>
    )
  }
}

const REQUEST_DELAY = 3000;

class AiNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
    this.requestReview = this.requestReview.bind(this);
  }

  componentDidMount() {
    this.fetchAISummary();
  }

  fetchAISummary() {
    let {trial_id, population_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations/${population_id}/text_summary`;
    request('GET', url).then(({ai_review}) => {
      this.setState({ai_review, loading: false})
      if (ai_review && ai_review.requesting_review) {
        setTimeout(this.requestReview, REQUEST_DELAY);
      }
    }).catch(error => {
      this.setState({loading: false});
    });
  }

  requestReview(force=false) {
    this.setState({loading: true});
    let {trial_id, population_id} = this.props;
    let url = `/admin/trials/${trial_id}/populations/${population_id}/openai_review`
    if (force) url += '?force_update=true';

    request('POST', url).then(response => {
      if (!response.loading && response.openai_review) {
        this.setState({ai_review: response.openai_review, loading: false});
      } else if (!response.loading && response.error) {
        let {error_message, error_code} = response;
        this.setState({error_message, error_code, loading: false});
      } else {
        setTimeout(this.requestReview, REQUEST_DELAY);
      }
    });
  }


  renderError(error_code, error_message) {
    return (
      <div>
        <h3>ERROR generating the AI Notes.</h3>
        {error_message} ({error_code})
        <button className="btn btn-default" onClick={e => this.requestReview(true)}>Generate AI Notes</button>
      </div>
    )
  }

  renderReview(ai_review) {
    if (!ai_review || !ai_review.openai_review || ai_review.openai_review.length === 0) return <div className="well">Review has not been generated yet</div>
    return (
      <Markdown text={ai_review.openai_review} />
    );
  }

  render() {
    let {loading, error_message, error_code, ai_review} = this.state;
    if (loading) return <Loading />
    if (error_code) return this.renderError(error_code, error_message);
    if (ai_review && ai_review.requesting_review) return <Loading />

    return (
      <div>
        {this.renderReview(ai_review)}
        <button className="btn btn-default" onClick={e => this.requestReview(true)}>Generate AI Notes</button>
      </div>
    )
  }
}

function buildTabs(props) {
  let extra_tabs = [];

  let tabs = [
    {id: 'data_summary', label: 'Entry Criteria'},
    {id: 'text_summary', label: 'Summary'},
    {id: 'ai_notes', label: 'AI Notes'}
  ]

  if (props.reference_id) {
    tabs.splice(0, 0, {id: 'reference', label: 'Reference'})
  }

  return tabs;
}
