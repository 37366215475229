import React, {Component} from 'react';
import PeopleWidget from './people_widget';

import Condition from './condition';
import DatePicker from './datepicker';
import MapViewer from './map_viewer';
import request from '../request';
import SearchCancerCenter from './search_cancer_center_modal';

import {Countries, TrialLocationAvailabilityOptions, TrialLocationSourceOfVerificationOptions} from '../constants.js.erb';

export class LocationForm extends Component {
  constructor(props) {
    super(props);
    let {location} =  props;

    // Override verified
    location._verified_data = {verified: location.verified, verified_at: location.verified_at, verified_notes: location.verified_notes, slot_availability: location.slot_availability, source_of_verification: location.source_of_verification}
    location.verified = false;
    location.verified_at = "";
    location.verified_notes = "";
    location.slot_availability = '';
    location.source_of_verification = '';

    this.state = {location: location};
    this.changeLocation = this.changeLocation.bind(this);
    this.changeLocationCheckbox = this.changeLocationCheckbox.bind(this);
    this.linkCancerCenter = this.linkCancerCenter.bind(this);
  }


  saveLocation(e) {
    e.preventDefault();

    let {location, cancer_center} = this.state;
    let locationData = getLocationParams(location);
    let {trial_id} = this.props;
    let params = {location: locationData}
    let method, url;

    if (location.id) {
      url = "/admin/trials/" + trial_id + "/locations/" + location.id;
      method = 'PUT';
    } else {
      url = "/admin/trials/" + trial_id + "/locations/";
      method = 'POST';
      params.cancer_center = cancer_center;
    }

    request(method, url, params).then(location => {
      window.location = "/admin/trials/" + trial_id + "/locations";
    });
  }

  unsetVerified(e) {
    e.preventDefault();
    if (!window.confirm('Are you sure?')) return;
    let location = this.state.location;
    location._reset_verified = true;
    location._verified_data = {verified: false}
    this.setState({location});
  }

  changeLocation(e) {
    let {name, value} = e.target;
    let location = this.state.location;
    location[name] = value;
    this.setState({location});
  }

  changeLocationCheckbox(e) {
    let {name, checked} = e.target;
    let location = this.state.location;
    location[name] = checked;
    if (name === 'verified' && checked) {
      location.verified_at = new Date();
    }
    this.setState({location});
  }

  changeGeoLocation(place) {
    let location = this.state.location;
    location._place = place;
    this.setState({location});
  }

  linkCancerCenter(cancer_center) {
    let {location} = this.state;
    console.log(location);
    location.name = cancer_center.name;
    location.city = cancer_center.city;
    location.state = cancer_center.state;
    location.zip = cancer_center.zip;
    location.country = cancer_center.country;
    this.setState({cancer_center, location});
  }

  renderVerification() {
    let {location} = this.state;
    if (location.verified || !location._verified_data.verified) return null;

    let date = moment(location._verified_data.verified_at).format('LL')
    let notes, availability, source_of_verification;
    if (location._verified_data.verified_notes) {
      notes = <p><span className="subtle">Notes:</span> {location._verified_data.verified_notes}</p>;
    }

    if (location._verified_data.slot_availability) {
      let option = TrialLocationAvailabilityOptions.find(o => o.id === location._verified_data.slot_availability);
      availability = <p><span className="subtle">Available Slots:</span> {option.name}</p>
    }

    if (location._verified_data.source_of_verification) {
      let option = TrialLocationSourceOfVerificationOptions.find(o => o.id === location._verified_data.source_of_verification);
      source_of_verification = <p><span className="subtle">Source of Verification:</span> {option.name}</p>
    }

    return (
      <div className="verification_info_holder">
        <p><b>{location.verified_by_name}</b> verified on {date}:</p>
        {notes}
        {availability}
        {source_of_verification}
        <button className="btn btn-warning btn-sm" onClick={e => this.unsetVerified(e)}>Set as not verified</button>
      </div>
    )
  }


  renderFakeCancerCenter(location) {
    if (!location.fake_cancer_center) return null;
    let {lat, lon} = location.fake_cancer_center;
    return (
      <div>
        <label>Location</label>
        <MapViewer lat={lat} lon={lon} zoom={7} width={650} height={400} />
      </div>
    );
  }

  renderAvailabilityWarning() {
    let {location} = this.state;
    if (location.slot_availability !== 'caveats_population_modified') return null;
    return (
      <p className="hint">WARNING: Caveats: Population Modified</p>
    )
  }



  renderForm() {
    let location = this.state.location;
    let status_content = Object.keys(gon.recruitementStatus).map(key => <option key={key} value={key}>{gon.recruitementStatus[key]}</option>);
    let countries_content = gon.countries.map(c => <option key={c.id} value={c.id}>{c.text}</option>);
    let states_content = gon.usaStates.map(s => <option key={s.key} value={s.id}>{s.label}</option>);

    return (
      <form onSubmit={e => this.saveLocation(e)} className="well">
        <div className="form-group">
          <label>Facility</label>
          <input type="text" className="form-control" value={location.name} name="name" onChange={this.changeLocation} />
        </div>

        <div className="form-group">
          <label>Status</label>
          <select className="form-control" value={location.status || ''} name="status" onChange={this.changeLocation}>
            <option value=""></option>
            {status_content}
          </select>
        </div>

        <div className="form-group">
          <label>City</label>
          <input type="text" className="form-control" value={location.city} name="city" onChange={this.changeLocation} />
        </div>

        <div className="form-group">
          <label>Country</label>
          <select className="form-control" value={location.country} name="country" onChange={this.changeLocation}>
            {countries_content}
          </select>
        </div>
        <Condition when={location.country == 'US'}>
          <div className="form-group">
            <label>State</label>
            <select className="form-control" value={location.state} name="state" onChange={this.changeLocation}>
              <option></option>
              {states_content}
            </select>
          </div>
        </Condition>

        <div className="form-group">
          <label>ZIP</label>
          <input type="text" className="form-control" value={location.zip} name="zip" onChange={this.changeLocation} />
        </div>

        <div className="checkbox">
          <label>
            <input type="checkbox" value={location.verified || false} checked={location.verified || false} name="verified" onChange={this.changeLocationCheckbox} />
            Verification Initiated</label>
        </div>

        <Condition when={location.verified}>
          <div className="form-subgroup-holder">
            <div className="form-group">
              <label>Date of Verification</label>
              <DatePicker className="form-control" value={location.verified_at} name="verified_at" onChange={this.changeLocation} />
            </div>

            <div className="form-group">
              <label>Source of Verification</label>
              <select className="form-control" value={location.source_of_verification || ''} name="source_of_verification" onChange={this.changeLocation}>
                <option value={''}>Unknown</option>
                {TrialLocationSourceOfVerificationOptions.map(o => {
                  return <option key={o.id} value={o.id}>{o.name}</option>
                })}
              </select>
            </div>


            <div className="form-group">
              <label>Verification Notes</label>
              <textarea className="form-control" value={location.verified_notes || ''} name="verified_notes" onChange={this.changeLocation} />
            </div>

            <div className="form-group">
              <label>Availability</label>
              {this.renderAvailabilityWarning()}
              <select className="form-control" value={location.slot_availability || ''} name="slot_availability" onChange={this.changeLocation}>
                <option value={''}>Unknown</option>
                {TrialLocationAvailabilityOptions.map(o => {
                  return <option key={o.id} value={o.id}>{o.name}</option>
                })}
              </select>
            </div>
          </div>

        </Condition>


        {this.renderVerification()}
        {this.renderFakeCancerCenter(location)}

        <br />


        <div>
          <input type="submit" className="btn btn-success" value="Submit" />
        </div>
        <hr />

          <p>Informational Only</p>
          <div className="row">
            <div className="col-md-6">
              <div className="checkbox">
                <label>
                  <input type="checkbox" value={location.user_edited || false} checked={location.user_edited || false} disabled="disabled" />
                Edited by Users?
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="checkbox">
                <label>
                  <input type="checkbox" value={location.user_linked || false} checked={location.user_linked || false} disabled="disabled" />
                  Linked by Users?
                </label>
              </div>
            </div>
          </div>
      </form>
    );
  }

  renderLinkedCancerCenter() {
    let {cancer_center} = this.state;
    if (!cancer_center) return <p><b>Select a Cancer Center to Link</b> (will populate form below with details)</p>;
    return(
      <p>
        <b>Linked Cancer Center:</b> {cancer_center.name} (save to link)
      </p>
    );
  }

  renderCancerCenter() {
    let {location} = this.state;
    if (location.id) return null;

    return (
      <div id="search_cancer_center" className="new_linked_holder">
        {this.renderLinkedCancerCenter()}
        <SearchCancerCenter onSelect={this.linkCancerCenter} buttonLabel="Select Cancer Center" excludeUmbrella={true}/>
      </div>
    )
  }


  renderContact(contact, label) {
    if (!contact) return null;

    let name = buildName(contact);
    return (
      <div>
        <b>{label}</b><br />
        Name: {name}<br />
        Phone: {contact.phone}<br />
        Email: {contact.email}<br />
        <QuickAdd person={contact} />
        <br />
      </div>
    );
  }

  renderInvestigator() {
    let location = this.state.location;
    if (!location.raw || !location.raw.investigator) return null;
    let investigator= location.raw.investigator;
    let name = buildName(investigator);

    return (
      <div>
        <b>Investigator</b><br />
        Name: {name}<br />
        Role: {investigator.role}<br />
        <QuickAdd person={investigator} />
        <br />

      </div>

    );
  }


  renderContacts() {
    let location = this.state.location;
    if (!location.id) return null;

    let {trial_id, people, people_widget_ctx} = this.props;

    let contact, backup_contact;
    if (location.raw) {
      contact = location.raw.contact;
      backup_contact = location.raw.backup_contact
    }

    return (
      <div>
        <h4><b>CONTACTS</b></h4>
        <br/>
        {this.renderContact(contact, "Contact")}
        {this.renderContact(backup_contact, "Backup Contact")}
        {this.renderInvestigator()}
        <PeopleWidget class_name="TrialLocation" record_id={location.id} people={people} context={people_widget_ctx} />
      </div>
    )
  }

  render() {
    let location = this.state.location;
    return (
      <div className="row">
        <div className="col-md-7">
          {this.renderCancerCenter()}
          {this.renderForm()}
        </div>
        <div className="col-md-5">
          {this.renderContacts()}
        </div>
      </div>
    )
  }
}


export function getLocationLabel(location, opt={}) {
  let chunks = []
  let country_chunks = []

  country_chunks.push(location.city);

  if (location.country === 'US') {
    country_chunks.push(location.state)
  } else {
    let country = locationCountryName(location);
    if (country) country_chunks.push(country);
  }

  chunks.push(country_chunks.filter(c => c && c.length > 0).join(', '))

  let address_chunks = []
  if (location.cancer_centers && location.cancer_centers.length > 0) {
    let cancer_center = location.cancer_centers[0];
    address_chunks.push(cancer_center.name);
  } else {
    address_chunks.push(location.name || location.address);
  }

  if (opt.show_zip && location.zip) {
    address_chunks.push(location.zip);
  }

  if (opt.show_status && location.status_humanized) {
    address_chunks.push(' (' + location.status_humanized + ')');
  }
  chunks.push(address_chunks.filter(c => c && c.length > 0).join(', '))


  return chunks.filter(c => c && c.length > 0).join(' - ')
}

export function locationsComparator(location_a, location_b)  {
  if (location_a.country !== 'US' && location_b.country === 'US') return 1;
  if (location_a.country === 'US' && location_b.country !== 'US') return -1;

  if (location_a.country === 'US' && location_b.country === 'US') {
    if (location_a.state_name > location_b.state_name) return 1;
    else if (location_b.state_name > location_a.state_name) return  -1;
  } else {
    if (location_a.country_name > location_b.country_name) return 1;
    else if (location_b.country_name > location_a.country_name) return  -1;
  }

  let name_a = location_a.name || location_a.address;
  let name_b = location_b.name || location_b.address;
  if (name_a > name_b) return 1;
  if (name_b > name_a) return -1;
  return 0;
}

function locationCountryName(location) {
  if(location.country_name && location.country_name.length > 0) {
    return location.country_name;
  }

  if (location.country && location.country.length > 0) {
    let country = Countries.find(c => c.id === location.country);
    if (country) return country.name;
  }

  return null;
}

function getLocationParams(location) {
  let {name, phone, city, zip, curated_zip, state, country, contact_first_name, contact_middle_name, contact_last_name, degrees, status, digest, hidden} = location;
  let data = {name, phone, city, zip, curated_zip, state, country, contact_first_name, contact_middle_name, contact_last_name, degrees, status, digest, hidden}

  if (location.verified) {
    data.verified = true;
    data.verified_at = location.verified_at;
    data.verified_notes = location.verified_notes;
    data.slot_availability = location.slot_availability;
    data.source_of_verification = location.source_of_verification;
  } else if (location._reset_verified) {
    data.verified = false;
    data.verified_at = null
    data.verified_notes = null
    data.slot_availability = null;
    data.source_of_verification = null;
  }

  return data;
}

function getContactParams(contact)  {
  let {first_name, last_name, phone, email, role, notes} = contact;
  return {first_name, last_name, phone, email, role, notes};
}

function buildName(contact) {
  let parts = [];
  if (contact.first_name) parts.push(contact.first_name);
  if (contact.middle_name) parts.push(contact.middle_name);
  if (contact.last_name) parts.push(contact.last_name);
  return parts.join(' ');
}

function defaultContact() {
  return {first_name: "", last_name: "", phone: "", email: "", degree: "", role: ""};
}

export class QuickAdd extends Component {
  format(source) {
    let person = Object.assign({}, source);

    if (person.role) {
      person.roles = [person.role]
      delete(person.role);
    }
    return JSON.stringify(person)
  }


  render() {
    let {person} = this.props;
    let person_json = this.format(person);
    return (
      <button className="btn btn-default btn-sm quick-add-person" data-person={person_json}>Quick Add</button>
    )
  }
}
