import React, {Component} from 'react';
import PopulationData from './population_data.js.erb';

export default class TumorTypeTraits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  renderHint(section) {
    if (!section.search_hint) return null;
    return <p className="hint">{section.search_hint}</p>

  }
  render() {
    let {tumor_type, options, onChange} = this.props;
    let data = PopulationData.cancer_type[tumor_type];

    return Object.keys(data).map(key => {
      let section = data[key];
      let section_options = Object.keys(section.options).map(opt_id => {
        let opt = section.options[opt_id];
        let label = opt.search_label || opt.label
        let value = (options[tumor_type] && options[tumor_type][key] && options[tumor_type][key][opt_id]) || false
        let className = "checkbox " + key;
        return (
          <div className={className} key={opt_id}>
            <label>
              <input type="checkbox" checked={value} onChange={e => onChange(key, opt_id, e)}/> {label}
            </label>
          </div>
        )
      });

      let no_details_value = (options[tumor_type] && options[tumor_type][key] && options[tumor_type][key]['_empty']) || false
      let title = section.search_title || section.title;
      let className = "form-group " + key;
      return (
        <div className={className} key={"options_" + key}>
          <label className="control-label">{title}</label>
          {this.renderHint(section)}
          <div className="checkbox select-empty">
            <label>
              <input type="checkbox" onChange={e => onChange(key, "_empty", e)} checked={no_details_value} />
              <i>Include results without this specified</i>
            </label>
            {section_options}
          </div>
        </div>
      );
    })

  }

}
