import React, {Component} from 'react';
import Form from '../form';
import {ECOG} from '../../constants.js.erb';

export default class PatientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = props.query;
    if (!this.state.ecog) {
      this.state.ecog = {};
      for (let key of Object.keys(ECOG)) {
        this.state.ecog[key] = false;
      }
    }

    this.inputChanged = this.inputChanged.bind(this);
    this.refreshParent = this.refreshParent.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
  }

  inputChanged(ev) {
    let key = ev.target.name
    let value = ev.target.value;
    this.setState({[key]: value}, this.refreshParent);
  }

  changeEcog(e) {
    let {name, checked} = e.target
    let {ecog} = this.state;
    ecog[name] = !!checked;

    if (name !== '_empty') {
      let any_selected = Object.keys(ECOG).some(key => ecog[key]);
      ecog['_empty'] = any_selected;
    }

    this.setState({ecog}, this.refreshParent);
  }

  changeFilters(patient_details) {
    let {filters, onChangeFilters} = this.props;
    filters.patient_details = patient_details;
    onChangeFilters({filters});
  }

  refreshParent() {
    this.props.onChange("patient_details", this.state);
  }

  renderFilters() {
    let {filters} = this.props;
    let fields = [
      {name: 'had_prior_therapy', type: 'select-boolean'}
    ];

    return <Form model={filters.patient_details} fields={fields} onChange={this.changeFilters} container_element="div" />
  }
  render() {
    let {age, gender, life_expentacy, ecog} = this.state;
    return (
      <div id="patient_details">
        <form>
          <div className="form-group">
            <label>Age</label>
            <input type="number" className="form-control" name="age" value={age || ""} onChange={this.inputChanged} />
          </div>

          <div className="form-group">
            <label>Gender</label>
            <select className="form-control" name="gender" value={gender} onChange={this.inputChanged}>
              <option value={null}></option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>

          <div>
            <label>Performance Status (ECOG or Zubrod / Karnofsky / Lansky)</label>
            <div className="checkbox">
              <label>
                <input type="checkbox" name="_empty" value={ecog._empty} checked={ecog._empty} onChange={e => this.changeEcog(e)} /><i>Include results without this specified</i>
              </label>
            </div>

            {Object.keys(ECOG).map(k => {
              return (
                <div className="checkbox" key={k}>
                  <label>
                    <input type="checkbox" name={k} value={ecog[k]} checked={ecog[k]} onChange={e => this.changeEcog(e)} />{ECOG[k]}
                  </label>
                </div>
              )
            })}
          </div>
          {this.renderFilters()}
        </form>

      </div>
    )
  }
}
