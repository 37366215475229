import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';
import * as Inflector from 'inflected';
import {PopulationRubricTypes} from '../constants.js.erb';

const TABS = [
  {id: 'openai', label: 'OpenAI', onDemand: true},
  {id: 'mistral', label: 'Mistral', filter: state => !!state.workflow_data.mistral},
  {id: 'deepseek', label: 'Deep Seek', filter: state => !!state.workflow_data.deepseek},
]
export default class ReferenceWorkflowAI extends Component {
  constructor(props) {
    super(props);
    this.state = {current_model: 'openai'};
    this.onChangeOverwriteData = this.onChangeOverwriteData.bind(this);
 }

  componentDidMount() {
    this.fetchWorkflowData();
  }

  fetchWorkflowData(sources=false) {
    let {reference_id} = this.props;
    if (sources === false) {
      sources = TABS.map(t => t.id);
    }

    this.setState({loading: true});

    let url = `/admin/references/${reference_id}/workflow_data?sources=${sources.join(',')}`;
    request('GET', url).then(response => {
      let workflow_data = this.state.workflow_data || {};
      let pending_sources = [];

      for (let source of sources) {
        let source_data = response.workflow_data[source];
        if (source_data) {
          if (source_data.requested_at) {
            pending_sources.push(source)
          } else {
            workflow_data[source] = source_data;
          }
        }
      }
      if (pending_sources.length > 0) {
        setTimeout(e => this.fetchWorkflowData(pending_sources), 3000);
      }

      this.setState({workflow_data, text_summary: response.text_summary, loading: false});
    }).catch(error => {
      this.setState({loading: false});
    });
  }

  requestWorkflowData(overwrite_data=null) {
    let {current_model} = this.state;

    let {reference_id} = this.props;
    let url = `/admin/references/${reference_id}/request_workflow_data`;
    return request('POST', url, {source: current_model, overwrite_data}).then((data) => {
      let {workflow_data} = this.state;
      workflow_data[current_model] = data;
      this.setState({current_model});
      setTimeout(e => this.fetchWorkflowData([current_model]), 3000);
    }).catch(error => {

    });
  }

  onChangeOverwriteData(overwrite_data) {
    this.requestWorkflowData(overwrite_data);
    this.setState({show_overwrite_form: false});
  }

  selectTab(e, current_model) {
    e.preventDefault();
    this.setState({current_model});
  }

  toggleJSON() {
    let show_json = !this.state.show_json;
    this.setState({show_json});
  }

  openOverwriteForm() {
    this.setState({show_overwrite_form: true});
  }

  renderNoAbstractText() {
    let {reference_id} = this.props;
    let url = `/admin/references/${reference_id}/edit`;
    return (
      <div>
        <strong>No abstract text set</strong>
        <p>Set abstract text to generate the summary</p>
        <a href={url} target="_blank">Edit Reference</a>
      </div>
    );
  }


  renderAdvanced(data) {
    let {triage_data} = data;
    let advanced_type = triage_data['overall_population'];
    let advanced_data = data[advanced_type];
    if (!advanced_data) return null;
    return (
      <div>
        <h3>{Inflector.titleize(advanced_type)}</h3>
        {advanced_data.populations.map(population => {
          return (
            <div key={population.name}>
              <h4>{population.name}</h4>
              {renderHash(population)}
            </div>
          );

        })}
      </div>
    )
  }


  renderTriage(data) {
    let {triage_data} = data;
    if (!triage_data) return null;
    return (
      <div>
        <h3>Triage</h3>
        {renderHash(triage_data)}
      </div>
    )
  }

  renderShowOverwriteButton() {
    let {current_model} = this.state;
    let tab_data = TABS.find(t => t.id === current_model);
    if (!tab_data.onDemand) return null;

    return <button className="btn btn-default" onClick={e => this.openOverwriteForm()}>Overwrite Data</button>
  }


  renderGenerateWorkflowButton() {
    let {current_model} = this.state;
    let tab_data = TABS.find(t => t.id === current_model);
    if (!tab_data.onDemand) return null;

    return <button className="btn btn-success" onClick={e => this.requestWorkflowData()}>Request Workflow Data</button>
  }

  renderToggleJsonButton() {
    let {show_json} = this.state;
    let label = show_json ? 'Show Summary' : 'Show Data';
    return <button className="btn btn-link" onClick={e => this.toggleJSON()}>{label}</button>
  }

  renderNoData() {
    return (
      <div>
        <h3>No data for this model</h3>
        {this.renderGenerateWorkflowButton()}
      </div>
    );
  }

  renderError(data) {
    return (
      <div>
        <h3>ERROR</h3>
        <p>Something went wrong trying to fetch data.</p>
        <pre>{data.error}</pre>
        {this.renderGenerateWorkflowButton()}
      </div>
    )
  }


  renderJSONContent(data) {
    return (
      <div>
        {this.renderTriage(data)}
        {this.renderAdvanced(data)}
      </div>
    )
  }

  renderTextContent(data) {
    return <Content data={data} />
  }

  renderContent() {
    let {current_model, workflow_data, loading, show_json} = this.state;
    let data = workflow_data[current_model];

    if (loading) return <Loading />
    if (!data) return this.renderNoData();
    if (data.requested_at) return <Loading />
    if (data.error) return this.renderError(data);
    let updated_at = moment(data.generated_at).format('LLL');
    let update_btn, edit_btn;
    let body = show_json ? this.renderJSONContent(data) : this.renderTextContent(data);

    return (
      <div className="reference_work_details">
        <h2>AI ANALYSIS</h2>
        {body}
        {this.renderToggleJsonButton()}
        <hr />
        <p><i>Data generated on {updated_at} with {current_model}</i></p>
        <div className="btn-group">
          {this.renderGenerateWorkflowButton()}
          {this.renderShowOverwriteButton()}
        </div>
      </div>
    );
  }


  renderTabs() {
    let {current_model, workflow_data} = this.state;
    let visible_tabs = TABS.filter(tab => !tab.filter || tab.filter(this.state) === true)
    if (visible_tabs.length < 2) return null;

    let tabs = visible_tabs.map(tab => {
      let active = tab.id === current_model;
      let className = active ? 'active' : '';
      return <li key={tab.id} role="presentation" className={className}><a href="#" onClick={e => this.selectTab(e, tab.id)}>{tab.label}</a></li>
    });

    return (
      <ul className="nav nav-tabs">
        {tabs}
      </ul>
    )
  }

  renderOverwriteForm() {
    let {overwrite_data} = this.state;
    return <OverwriteForm overwrite_data={overwrite_data} onSubmit={this.onChangeOverwriteData} onCancel={e => this.setState({show_overwrite_form: false})} />
  }

  render() {
    let {workflow_data, show_overwrite_form} = this.state;
    if (show_overwrite_form) return this.renderOverwriteForm()
    if (!workflow_data) return <Loading />
    return (
      <div className="ai_output_holder">
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    )
  }
}


class OverwriteForm extends Component {
  constructor(props) {
    super(props);
    let overwrite_data = this.stateFromOverwriteData(props.overwrite_data || {})
    this.state = {overwrite_data};
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  stateFromOverwriteData(overwrite_data) {
    let data = {overall_population: overwrite_data.overall_population};
    if (overwrite_data.has_scorable_data) {
      data.scorable_or_notable_type = 'scorable';
    } else if (overwrite_data.has_notable_data) {
      data.scorable_or_notable_type = 'notable';
    }
    return data;
  }

  overwriteDataFromState(data) {
    let overwrite_data = {overall_population: data.overall_population};
    if (data.scorable_or_notable_type === 'scorable') {
      overwrite_data.has_scorable_data = true;
      overwrite_data.has_notable_data = false;
    } else if (data.scorable_or_notable_type === 'notable') {
      overwrite_data.has_scorable_data = false;
      overwrite_data.has_notable_data = true;
    }
    return overwrite_data;
  }

  onSubmit(e) {
    e.preventDefault();
    let {onSubmit} = this.props;
    let {overwrite_data} = this.state;
    onSubmit(this.overwriteDataFromState(overwrite_data));
  }

  onChange(overwrite_data) {
    this.setState({overwrite_data});
  }

  render() {
    let {overwrite_data} = this.state;
    let {onCancel} = this.props;
    let fields = [
      {name: 'scorable_or_notable_type', type: 'select', collection: {scorable: 'Scorable', notable: 'Notable'}},
      {name: 'overall_population', type: 'select', collection: {solid_advanced: 'Solid Advanced', solid_early: 'Solid Early'}}
    ]
    return (
      <div className="overwrite_form_holder">
        <Form model={overwrite_data} fields={fields} submitLabel="Overwrite Data" onChange={this.onChange} onSubmit={this.onSubmit} onCancel={onCancel} />
      </div>
    )
  }
}

class Content extends Component {

  renderPopulations() {
    let {data} = this.props;
    let {triage_data} = data;
    let {overall_population} = triage_data
    let details = data[triage_data.overall_population];
    if (!details) return null;

    return (
      <div>
        <p><b>AI-DEFINED POPULATIONS</b></p>
        {details.populations.map((population, index) => {
          let keymap;
          if (overall_population === 'solid_advanced') {
            keymap = ADVANCED_EFFICACY_DATA_KEY_LABELS;
          } else if (overall_population === 'solid_early') {
            keymap = EARLY_EFFICACY_DATA_KEY_LABELS;
          } else if (overall_population.startsWith('liquid')) {
            keymap = LIQUID_EFFICACY_DATA_LABELS;
          } else {
            throw('Invalid overall population:' + overall_population);
          }

          return (
            <div key={index} className="population workflow_population_display">
              <div className="main-population-info">
                <h3>{population.name}</h3>
                <p><i>{population.number_of_patients_treated} patients, treated with {population.interventions.join(', ')}</i></p>
              </div>
              <div className="efficacy">
                <p className="final_rating"><b>Efficacy: <span>{population.highest_ranked_efficacy_data_rating} stars</span></b></p>
                <p>{population.efficacy_description}</p>
                {renderDataKeyMap(keymap, population.efficacy_data_available, "Efficacy Data Available")}
                <p>Highest Ranked: <b>{population.highest_ranked_efficacy_data}</b></p>
                
                <p><i>{population.efficacy_rating_justification}</i></p>
              </div>

              <div className="safety">
                <p className="final_rating"><b>Safety: <span>{population.highest_ranked_safety_data_rating} stars</span></b></p>
                <p>{population.safety_description}</p>
                {renderDataKeyMap(SAFETY_DATA_KEY_LABELS, population.safety_data_available, "Safety Data Available")}
                <p>Highest Ranked: <b>{population.highest_ranked_safety_data}</b></p>
                
                <p><i>{population.safety_rating_justification}</i></p>
              </div>
            </div>
          );
        })}
      </div>
    )
  }




  render() {
    let {triage_data} = this.props.data;
    return (
      <div>
        <div className="triage_details">
          <p>{triage_data.significant_finding}</p>
          <ul>
            <li><b>Type:</b> {triage_data.reference_description}</li>
            <li><b>Scorable:</b> {renderValue(triage_data.has_scorable_data)}</li>
            <li><b>Notable:</b> {renderValue(triage_data.has_notable_info)}</li>
            <li><b>Total Number of Patients:</b> {triage_data.number_of_patients_treated}</li>
            <li><b>Rubric:</b> {triage_data.overall_population}</li>
          </ul>
        </div>
        {this.renderPopulations()}
      </div>
    )
  }

}

function renderValue(value) {
  if (value === true) return 'Yes';
  if (value === false) return 'No';
  if (typeof value === 'string' || value instanceof String) return value;
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) return renderHash(value);

  return value;
}

function renderHash(data) {
  if (!data) return null;
  return (
    <ul>
      {Object.keys(data).map(key => {
        let title = key;
        let value = renderValue(data[key]);
        return (
          <li key={key}>
            {title} : {value}
          </li>
        )
      })}
    </ul>
  )
}


const EARLY_EFFICACY_DATA_KEY_LABELS = {
  '1_DFS_EFS_RFS_PFS_OS_at_three_years': '3 year',
  '2_DFS_EFS_RFS_PFS_OS_at_two_years': '2 year',
  '3_DFS_EFS_RFS_PFS_OS_at_one_year': '1 year',
  '4_mDFS_mEFS_mRFS_mPFS_mOS': 'Median Rate',
  '5_hazard_ratios': 'Hazard Ratio',
  '6_pCR_NED': 'pCR or NED'
}

const ADVANCED_EFFICACY_DATA_KEY_LABELS = {
  '1_mDFS_mEFS_mRFS_mPFS': 'Median Rate',
  '2_mOS': 'Median OS',
  '3_DFS_EFS_RFS_PFS_around_18_month': '18 month',
  '4_DFS_EFS_RFS_PFS_around_12_months': '12 month',
  '5_DFS_EFS_RFS_PFS_around_6_months': '6 month',
  '6_OS_around_18_month': '18 month OS',
  '7_OS_around_12_months': '12 month OS',
  '8_OS_around_6_months': '6 month OS',
  '9_hazard_ratios': 'Hazard Ratio',
  '10_CBR_DCR': 'CBR or DCR',
  '11_ORR': 'ORR',
  '12_mDOR': 'mDOR',
  '13_case_report': 'Case Reports'
}

const LIQUID_EFFICACY_DATA_LABELS = {
  '1_DFS_EFS_RFS_PFS_TTP_OS_at_3_years': '3 year',
  '2_DFS_EFS_RFS_PFS_TTP_OS_at_2_years': '2 year',
  '3_DFS_EFS_RFS_PFS_TTP_OS_at_1_year': '1 year',
  '4_mDFS_mEFS_mRFS_mPFS_mTTP_mOS': 'Median Rate',
  '5_CR_CRi': 'Complete Response',
  '6_responses': 'Responses',
  '7_case_report': 'Case Reports'
}

const SAFETY_DATA_KEY_LABELS = {
  '1_discontinuation': 'Discontinuation',
  '2_grade_3_or_4_non_lab': 'Grade 3/4 Non-Lab',
  '3_grade_3_or_4_lab': 'Grade 3/4 Lab',
  '4_grade_3_or_4_any': 'Grade 3/4 Any',
  '5_common_trae_non_lab': 'Common Non-Lab',
  '6_common_trae_lab': 'Common Lab',
  '7_common_trae_any': 'Common Any'
}

function renderDataKeyMap(keymap, data, defaultLabel) {
  if (typeof data !== 'object' || Array.isArray(data) || data === null) {
    return <p className="empty_data_key_map">{defaultLabel}: {renderValue(data)}</p>
  }

  return (
    <ul>
      {Object.keys(keymap).map(key => {
        let value = data[key];
        if (value === undefined || value === 'not reported') return null;
        let label = keymap[key];

        return (
          <li key={key}>
            <b>{label}:</b> {value}
          </li>
        )
      })}
    </ul>
  )
}
