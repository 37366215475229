import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import DiagnosticAndGeneOptions from './dignostic_and_gene_options';
import {CancerTypeSearchKey, DiagnosticsGroupSearchKey} from '../constants.js.erb';

export default class DiagnosticForm extends Component {
  constructor(props) {
    super(props);
    let {diagnostic} = props;
    this.state = {diagnostic};
    this.onChange = this.onChange.bind(this);
    this.onChangeResults = this.onChangeResults.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(diagnostic) {
    this.setState({diagnostic});
  }

  onChangeResults(results) {
    let {diagnostic} = this.state;
    diagnostic.results = results;
    this.setState({diagnostic});
  }

  onSubmit(e) {
    let {diagnostic} = this.state;
    let url = '/admin/diagnostics/';
    let method;
    if (diagnostic.id) {
      url += '/' + diagnostic.id;
      method = 'PUT';
    } else {
      method = 'POST'
    }

    request(method, url, {diagnostic: diagnostic}).then(({success, errors}) => {
      if (success) {
        window.location = "/admin/diagnostics";
      } else {
        this.setState({errors});
      }

    });
  }

  formFields() {
    let {lab_types, classifications, connected_populations} = this.props;
    return [
      {name: 'name', required: true},
      {name: 'lab_type', required: true, type: 'select', collection: lab_types},
      {name: 'aliases'},
      {name: 'cancer_type_ids', label: 'Common Cancer Types - do not add or update unless specified', hint: 'Will change search interface.', type: 'slektr', multiple: true, src: "/admin/cancer_types/as_options.json", search_key: CancerTypeSearchKey, link: '/admin/cancer_types/$ID/edit'},
      {name: 'completely_qualitative', type: 'bool', visible: d => d.lab_type === 'qualitative'},
      // {name: 'diagnostic_classification', type: 'select', collection: classifications},
      {name: 'diagnostics_group_ids', label: 'Diagnostics Groups', type: 'slektr', multiple: true, src: '/admin/diagnostics_groups/as_options.json', search_key: DiagnosticsGroupSearchKey, link: '/admin/diagnostics_groups/$ID'},
      {name: 'normal_top', type: 'number', visible: d => d.lab_type === 'quantitative'},
      {name: 'normal_bottom', type: 'number', visible: d => d.lab_type === 'quantitative'},
      {name: 'patient_friendly_description', type: 'text'},
      {name: 'results', label: 'Results', type: DiagnosticResults, connected_populations: connected_populations}
    ];
  }

  render() {
    let {diagnostic, errors} = this.state;
    let {connected_populations} = this.props;
    let button_label = diagnostic.id ? 'Update Diagnostic' : 'Create Diagnostic';
    return (
      <div className="diagnostic_form_holder">
        <Form prefix="diagnostic" model_name="Diagnostic" model={diagnostic} fields={this.formFields()} onChange={this.onChange} onSubmit={this.onSubmit} errors={errors} />
      </div>
    );
  }
}

class DiagnosticResults extends DiagnosticAndGeneOptions {

  findConnectedPopulations(diagnostic) {
    let {model, field} = this.props;
    let {connected_populations} = field;
    if (!connected_populations) return [];

    return connected_populations.filter(population => {
      let data = population.data.genes_markers;
      for (let section of ['required', 'prohibited']) {
        if (data[section]) {
          for (let group of data[section]) {
            if (group.collection) {
              for (let record of group.collection) {
                if (record.diagnostics_and_gene_id == model.id && record.results && record.results.findIndex(r => r === diagnostic.id) !== -1) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    });
  }


  findPopulationsConnectedToAny() {
    let {model, field} = this.props;
    let {connected_populations} = field;
    if (!connected_populations) return [];

    return connected_populations.filter(population => {
      let data = population.data.genes_markers;
      for (let section of ['required', 'prohibited']) {
        if (data[section]) {
          for (let group of data[section]) {
            if (group.collection) {
              for (let record of group.collection) {
                if (record.diagnostics_and_gene_id == model.id && !record.results) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    });
  }

  renderHeader() {
    return (
      <div>
        <h3>Possible Results (for populations)</h3>
        <p>Results added here will be available for setting population requirements. Some trials might require only that diagnostic test has been performed, but others will require a certain result or range. These options can be added and managed below.</p>
      </div>
    )
  }

  renderFormHeader() {
    let {current_value} = this.state;
    let title = current_value.id ? 'Edit Possible Result (include units)' : 'Add New Possible Result with Units';
    return <h4><b>{title}</b></h4>;
  }

  renderAny() {
    let gene_any = {id: 'any', name: 'Any Result'};
    let populations = this.findPopulationsConnectedToAny();
    let populations_btn;
    if (populations.length > 0) {
      populations_btn = <button className="btn btn-link" onClick={e => this.showModal(gene_any, populations)}>Tagged in {populations.length} populations</button>
    }

    return (
      <tr>
        <td><strong><i>Any Result</i></strong></td>
        <td>{populations_btn}</td>
        <td>
        </td>
      </tr>
    );
  }

}
