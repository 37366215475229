import React, {Component} from 'react';
import AbstractTable from './abstract_table';

export default class RelatedPopulations extends Component {
  constructor(props) {
    super(props)
    let {data} = props;
    let populations_count = populationsCount(data);
    this.state = {data, populations_count , hide_archived: false}
  }


  toggleActive(e) {
    let hide_archived = e.target.checked;

    let {data} = this.props;
    if (hide_archived) {
      data = data.filter(row => row.status !== 'Archived')
    }
    let populations_count = populationsCount(data);
    this.setState({hide_archived, data, populations_count});
  }


  render() {
    let {data, populations_count, hide_archived} = this.state;
    return (
      <div>
        <label>
          Hide Archived <input type="checkbox" checked={hide_archived} value={hide_archived} onChange={e => this.toggleActive(e)} />

        </label>
        <p>{populations_count} Populations Found ({data.length} Trial and References)</p>
        <AbstractTable fields={FIELDS} data={data} />
      </div>
    );
  }
}

const FIELDS = [
  {name: 'parent_type', callback: renderParentType},
  {name: 'status', label: 'Status in app'},
  {name: 'name'},
  {name: 'overall_status_humanized', label: 'Trial Status'},
  {name: 'populations', callback: renderPopulations}
];


function renderParentType(row) {
  if (row.type === 'trial') {
    let link = `/admin/trials/${row.id}`;
    let edit_link = link + '/edit';
    return (
      <span>
        <a href={link}>Trial</a> | <a href={edit_link}>Edit</a>
      </span>
    );
  } else {
    let link = `/admin/references/${row.id}`;
    return <a href={link}>Reference</a> ;
  }
}


function renderPopulations(row) {
  if (!row.populations || row.populations.length === 0) return null;
  return (
    <ul>
    {row.populations.map(population => {
      let link;
      if (row.type === 'trial') {
        link = `/admin/trials/${population.trial_id}/populations/${population.id}`;
      } else {
        link = `/admin/trials/${population.reference_id}/populations/${population.id}`;
      }

      return <li key={population.id} ><a href={link}>{population.name}</a></li>
      })}
    </ul>
  );
}


function populationsCount(data) {
  let count = 0;
  for (let row of data) {
    count += row.populations.length;
  }
  return count;
}
