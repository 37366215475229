import React, {Component} from 'react';
import Form from './form';
import joinReact from './join_react';
import request from '../request';

export default class CancerCategoryForm extends Component {
  constructor(props) {
    super(props);
    let {cancer_category} = props;
    this.state = {cancer_category};
    this.onChange = this.onChange.bind(this);
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {cancer_category} = this.state;

    let url = "/admin/cancer_categories";
    let method, was_new;


    if (cancer_category.id) {
      url += "/" + cancer_category.id;
      method = "PUT"
    } else {
      was_new = true;
      method = "POST"
    }

    request(method, url, {cancer_category}).then(cancer_category => {
      this.setState({cancer_category, errors: [], submitting: false});
      if (!was_new) window.location = "/admin/cancer_categories/";

    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  onChange(field, value) {
    let {cancer_category} = this.state;
    cancer_category[field] = value;
    this.setState({cancer_category});
  }


  renderTitle() {
    let {cancer_category} = this.state;

    if (cancer_category.id) return <h1>Edit Cancer Category</h1>
    else return <h1>New Cancer Category</h1>
  }

  renderForm() {
    let {cancer_category, errors} = this.state;

    let fields = [
      {name: 'name', required: true},
      {name: 'tumor_type', type: 'radio', collection: TUMOR_TYPE_OPTIONS, required: true},
      {name: 'tags', type: Tags}
    ];

    return (
      <div id="cancer_category_form">
        <Form model_name="Cancer Category" model={cancer_category} fields={fields} errors={errors} onChangeField={this.onChange} />
      </div>
    );
  }

  renderSubmitButton() {
    let {cancer_category, submitting} = this.state;
    return <Form model_name="Cancer Category" model={cancer_category} fields={[]} onSubmit={e => this.submit(e)} submitting={submitting} />
  }


  renderTraits() {
    let {traits, super_admin} = this.props;
    let {cancer_category} = this.state;
    if (!cancer_category.id) return null;

    return <Traits cancer_category={cancer_category} traits={traits} super_admin={super_admin}/>
  }

  render() {
    let {cancer_category} = this.state;

    return (
      <div>
        {this.renderTitle()}
        <hr/>
        <div className="well">
          {this.renderForm()}

          {this.renderSubmitButton()}
        </div>
        {this.renderTraits()}
      </div>
    );
  }
}


class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {selected: {}};
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    let {selected} = this.state;
    let {value, onChange} = this.props;

    if (selected.id) {
      let index = value.findIndex(t => t.id === selected.id);
      value[index] = selected;
    } else {
      selected.id = generateUUID();
      value.push(selected);
    }

    onChange('tags', value);
    this.setState({selected: {}})
  }

  delete(tag) {
    if (!confirm('Are you sure?')) return;
    let {value, onChange} = this.props;
    let index = value.findIndex(t => t.id === tag.id);
    value.splice(index, 1);
    onChange('tags', value);
  }

  moveTagUp(tag) {
    let {value, onChange} = this.props;
    let index = value.findIndex(t => t.id === tag.id);
    value.splice(index, 1);
    value.splice(index - 1, 0, tag);
    onChange('tags', value);
  }

  moveTagDown(tag) {
    let {value, onChange} = this.props;
    let index = value.findIndex(t => t.id === tag.id);
    value.splice(index, 1);
    value.splice(index + 1, 0, tag);
    onChange('tags', value);
  }


  onCancel() {
    this.setState({selected: {}})
  }

  onChange(selected) {
    this.setState({selected})
  }

  select(tag) {
    let selected = Object.assign({}, tag);
    this.setState({selected})
  }

  renderForm() {
    let {selected} = this.state;
    let fields = [{name: 'text', required: true, label: "Add Tag"}];

    let cancelCallback;
    if (selected.id) {
      cancelCallback = e => this.onCancel(e);
    }

    return <Form containerElement='div' key="form" model_name="Tag" model={selected} fields={fields} onChange={this.onChange} onSubmit={e =>this.onSubmit(e)} onCancel={cancelCallback} />
  }


  render() {
    let {model} = this.props;
    let {selected} = this.state;
    return (
      <div className="option-list">
        <h4><b>Tags</b></h4>
        <hr/>
        {model.tags.map(tag => {
          return (
            <div key={tag.id} className="single-option">
              <div className="btn-group">
                <button className="btn btn-sm btn-default" onClick={e => this.moveTagUp(tag)}><span className="fa fa-arrow-up"/></button>
                <button className="btn btn-sm btn-default" onClick={e => this.moveTagDown(tag)}><span className="fa fa-arrow-down"/></button>
                <button className="btn btn-sm btn-primary" onClick={e => this.select(tag)}><span className="fa fa-pencil-alt" /></button>
                <button className="btn btn-sm btn-danger" onClick={e => this.delete(tag)}><span className="fa fa-trash"/></button>
              </div>
              <span className="single-option-text">{tag.text}</span>
            </div>
          );
        })}
        <div className="well">
          {this.renderForm()}
        </div>
      </div>
    );

  }
}


class Traits extends Component {
  constructor(props) {
    super(props);
    let traits = props.traits || [];
    this.state = {traits};
    this.submit = this.submit.bind(this);
  }

  submit() {
    let {current} = this.state;
    this.setState({submitting: true});
    let {cancer_category} = this.props;
    let {traits} = this.state;

    let method, was_new;
    let url = `/admin/cancer_categories/${cancer_category.id}/traits`;
    if (current.id) {
      url += "/" + current.id;
      method = "PUT"
    } else {
      was_new = true;
      method = "POST"
    }

    return request(method, url, {trait: current}).then(trait => {
      if (was_new) {
        traits.push(trait);
      } else {
        let index = traits.findIndex(t => t.id === trait.id);
        traits[index] = trait;
      }

      this.setState({traits, current: null, submitting: false, changed: false});
    }).catch(({errors}) => {
      window.alert(Object.values(errors).join("\n"))
      this.setState({errors, submitting: false});
    });
  }

  newTrait() {
    this.setState({current: defaultTrait()});
  }

  select(trait) {
    let current = Object.assign({}, trait);
    this.setState({current});
  }


  moveTraitUp(trait) {
    let {cancer_category} = this.props;
    let url = `/admin/cancer_categories/${cancer_category.id}/traits/${trait.id}/move_up`;
    request('PUT', url).then(traits => {
      this.setState({traits});
    });
  }

  moveTraitDown(trait) {
    let {cancer_category} = this.props;
    let url = `/admin/cancer_categories/${cancer_category.id}/traits/${trait.id}/move_down`;
    request('PUT', url).then(traits => {
      this.setState({traits});
    });
  }


  delete(trait) {
    if (!confirm('Are you sure?')) return;
    let {cancer_category} = this.props;
    let {current, traits} = this.state;

    let url = `/admin/cancer_categories/${cancer_category.id}/traits/${trait.id}`;
    request('DELETE', url).then(e => {
      let index = traits.findIndex(t => t.id === trait.id);
      traits.splice(index, 1);
      this.setState({traits});
    });
  }

  onChangeCurrent(current) {
    this.setState({current, changed: true});
  }

  renderTrait(trait) {
    let {super_admin} = this.props;
    let search_side_el, applies_to_all_el, essential_el, stage_trait_el, delete_trait_el;
    let id = "trait_" + trait.id;
    if (trait.search_side_label && trait.search_side_label.length > 0) {
      search_side_el = <p>{trait.search_side_label}</p>
    }
    if (trait.applies_to_all) {
      applies_to_all_el = <strong>Applies to all</strong>;
    }
    if (trait.essential) {
      essential_el = <strong>Essential</strong>;
    }

    if (trait.stage_trait) {
      stage_trait_el = <strong>Stage Trait</strong>
    }

    if (super_admin) {
      delete_trait_el = <button className="btn btn-danger btn-sm" title="Delete" onClick={e => this.delete(trait)}><span className="fa fa-trash"></span></button>

    }
    let option_labels = [applies_to_all_el, essential_el,  stage_trait_el].filter(l => l);
    let option_labels_el = joinReact(option_labels, ' | ');

    return (
      <div id={id} key={trait.id} className="panel panel-default">
        <div className="panel-body">
          <h4><button className="btn btn-link" onClick={e => this.select(trait)} title={"Trait ID: " + trait.id}>{trait.name}</button></h4>
          {search_side_el}
          {option_labels_el}
          <p><small>{trait.option_type_humanized}</small></p>

          {trait.options.map(option => {
            return <span key={option.id} className="badge">{option.text}</span>
          })}

          <hr />
          <div className="trait-footer">
            <div className="btn-group">
              <button className="btn btn-sm btn-default" onClick={e => this.moveTraitUp(trait)}><span className="fa fa-arrow-up"/></button>
              <button className="btn btn-sm btn-default" onClick={e => this.moveTraitDown(trait)}><span className="fa fa-arrow-down"/></button>
              <button className="btn btn-primary btn-sm" onClick={e => this.select(trait)}><span className="fa fa-pencil-alt"></span></button>
              {delete_trait_el}
            </div>
            <p className="small-text">Click edit and then make edits in the form above.</p>
          </div>
        </div>
      </div>
    );
  }

  renderList(){
    let {traits} = this.state;
    return (
      <div>
        <button className="btn btn-default" onClick={e => this.newTrait()}>Add Trait</button>
        <div id="traits_list">
          {traits.map(trait => this.renderTrait(trait))}
        </div>
      </div>
    )
  }


  renderForm() {
    let {super_admin} = this.props;
    let {current, submitting, errors, changed} = this.state;

    let fields = [
      {name: 'name', required: true},
      {name: 'search_side_label'},
      {name: 'essential', type: 'bool'},
      {name: 'applies_to_all', label: "Applies to all types in category?", type: 'bool'},
      {name: 'stage_trait', type: 'bool'},
      {name: 'option_type', type: 'radio', collection: TRAIT_OPTION_TYPE, visible: c => !c.stage_trait},
      {name: 'summary_text', type: 'text', label: 'Summary Sentence Text'},
      {name: 'options', type: TraitOptions, super_admin: super_admin}
    ];

    let changed_el;
    if (changed) {
      changed_el = <p>Trait changed. Remember to submit the form to save the changes</p>
    }
    return (
      <div className="well">
        <Form model_name="Trait" model={current} fields={fields} onChange={c => this.onChangeCurrent(c)} onSubmit={this.submit} onCancel={e => this.setState({current: null})} submitting={submitting}  errors={errors} changed={changed}/>
        {changed_el}
      </div>
    );
  }


  renderContent() {
    let {current} = this.state;
    if (current) {
      return this.renderForm();
    } else {
      return this.renderList();
    }
  }

  render() {
    return (
      <div id="traits">
        <h2>Traits</h2>
        <hr/>
        {this.renderContent()}
      </div>
    )
  }
}


class TraitOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit() {
    let {current_option} = this.state;
    let {value, onChange} = this.props;
    if (current_option.id) {
      let index = value.findIndex(o => o.id === current_option.id);
      value[index] = current_option;
    } else {
      current_option.id = generateUUID();
      value.push(current_option);
    }

    onChange('options', value);
    this.setState({current_option: null});
  }

  onChangeOption(current_option) {
    this.setState({current_option});
  }

  selectOption(option) {
    let current_option = Object.assign({}, option);
    this.setState({current_option});
  }

  newOption() {
    this.setState({current_option: {}});
  }

  moveOptionUp(option) {
    let {value, onChange} = this.props;
    let index = value.findIndex(o => o.id === option.id);
    if (index === 0) return;
    value.splice(index, 1);
    value.splice(index - 1, 0, option);
    onChange('options', value);
  }

  moveOptionDown(option) {
    let {value, onChange} = this.props;
    let index = value.findIndex(o => o.id === option.id);
    if (index === value.length -1) return;
    value.splice(index, 1);
    value.splice(index + 1, 0, option);
    onChange('options', value);
  }

  deleteOption(option) {
    if (!confirm('Are you sure?')) return;
    let {value, onChange} = this.props;
    let {current, traits} = this.state;
    let option_index = value.findIndex(o => o.id === option.id);
    value.splice(option_index, 1);
    onChange('options', value);
  }

  renderList() {
    let {model, field} = this.props;
    let {super_admin} = field;

    return (
      <div className="option-list">
        <h4><b>Options</b></h4>
        <hr/>
        <button className="btn btn-default" onClick={e => this.newOption()}>New Option</button>
        {model.options.map(option => {
          let search_side_el, stage_trait_el, delete_option_el;

          if (option.search_side_label && option.search_side_label.length > 0) {
            search_side_el = <span>[{option.search_side_label}]</span>
          }

          if (model.stage_trait && option.stage_trait_type) {
            stage_trait_el = <strong>[{STAGE_TRAIT_TYPES[option.stage_trait_type]}]</strong>;
          }

          if (super_admin) {
            delete_option_el = <button className="btn btn-danger btn-sm" onClick={e => this.deleteOption(option)}><span className="fa fa-trash"></span></button>;
          }

          return (
            <div key={option.id} className="single-option">
              <div className="btn-group">
                <button className="btn btn-sm btn-default" onClick={e => this.moveOptionUp(option)}><span className="fa fa-arrow-up"/></button>
                <button className="btn btn-sm btn-default" onClick={e => this.moveOptionDown(option)}><span className="fa fa-arrow-down"/></button>
                <button className="btn btn-primary btn-sm" onClick={e => this.selectOption(option)}><span className="fa fa-pencil-alt"></span></button>
                {delete_option_el}
              </div>
              <span className="single-option-text">{option.text} {search_side_el} {stage_trait_el}</span>
            </div>
          );
        })}
      </div>
    );
  }

  renderForm() {
    let {current_option} = this.state;
    let {model} = this.props;
    let fields = [
      {name: 'text', required: true},
      {name: 'stage_trait_type', type: 'select', collection: STAGE_TRAIT_TYPES, required: model.stage_trait, visible: t => model.stage_trait},
      {name: 'search_side_label'},
      {name: 'summary_text', type: 'text'},
    ];

    let title_prefix = current_option.id ? 'Edit' : 'New';
    return (
      <div className="well">
        <h4>{title_prefix} Option</h4>
        <Form containerElement='div' model_name="Option" model={current_option} fields={fields} onChange={o => this.onChangeOption(o)} onSubmit={e => this.onSubmit(e)} onCancel={e => this.setState({current_option: null})} />
      </div>
    );
  }

  renderContent() {
    let {current_option} = this.state;
    if (current_option) {
      return this.renderForm();
    } else {
      return this.renderList();
    }
  }

  render() {
    return (
      <div>
        {this.renderContent()}
      </div>

    );
  }
}

function defaultTrait() {
  return {option_type: 'M', options: []}
}

const TUMOR_TYPE_OPTIONS = {
  S: 'Solid',
  L: 'Liquid'
}

const TRAIT_OPTION_TYPE =  {
  S: 'Radio (can only choose one)',
  M: 'Checkbox (can choose multiple)'
}

const STAGE_TRAIT_TYPES = {
  local: 'Local',
  advanced: 'Advanced',
  metastatic: 'Metastatic'
}
