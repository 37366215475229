import React, {Component} from 'react';
import MapViewer from '../map_viewer';
import SearchResult from './results';
import {calculateDistance} from '../geolocation';

const GREEN = '#55ba47';
const BLUE = '#008bff';
const GREY = '#737373';
const LIGHT_GRAY = '#989898';
const RED = '#ff0000';

const MAX_DISTANCE = 100;


export default class ResultsMap extends Component {
  constructor(props) {
    super(props);
    let markers = this.buildMarkers(props);
    this.state = {markers};
    this.resizeWindow = this.resizeWindow.bind(this);
    this.updateMakers = this.updateMakers.bind(this);
  }

  componentDidMount() {
    window.addEventListener('result_stared', this.updateMakers, false);
    window.addEventListener('resize', this.resizeWindow, false);
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('result_stared', this.updateMakers);
    window.removeEventListener('resize', this.resizeWindow);
  }

  componentDidUpdate(prevProps) {
    let fields = ['hide_duplicated_trials', 'hide_non_us', 'selection', 'results'];
    for (let field of fields) {
      if (prevProps[field] !== this.props[field]) {
        this.updateMakers();
        return;
      }
    }
  }


  calculateDistances(patient, results, query) {
    if (!results || results.length === 0) return [];

    let {extra_locations} = query.locations;
    let locations = [];

    if (patient && patient.locations.length > 0) {
      locations = locations.concat(patient.locations);
    }

    if (extra_locations && extra_locations.length > 0) {
      locations = locations.concat(extra_locations);
    }

    for (let result of results) {
      for (let trial_location of result.trial.trial_locations) {
        for (let cancer_center of trial_location.cancer_centers) {
          cancer_center._distances = {};
          if (cancer_center.lon && cancer_center.lat) {
            for (let location of locations) {
              if (location.lon && location.lat) {
                let distance = Math.round(calculateDistance(location, cancer_center));
                cancer_center._distances[location.id] = distance;
              }
            }
          }
        }
      }
    }

    return results;
  }


  buildMarkers({results, patient, selection, hide_duplicated_trials, hide_non_us, query}) {
    results = this.calculateDistances(patient, results, query);

    let {extra_locations} = query.locations;
    let all_locations = [];

    if (patient && patient.locations.length > 0) {
      all_locations = all_locations.concat(patient.locations);
    }

    if (extra_locations && extra_locations.length > 0) {
      all_locations = all_locations.concat(extra_locations);
    }


    // First, group results by cancer center
    let all_centers = [];
    for (let result of results) {
      let trial_centers = [];
      let trial_id = result.trial.id;
      let selected = selection.indexOf(trial_id) !== -1;

      // First, collect all the cancer centers
      for (let trial_location of result.trial.trial_locations) {
        for (let cancer_center of trial_location.cancer_centers) {
          let {id, lat, lon} = cancer_center;
          if (lat && lon) {
            let title = cancer_center.name;
            let index = trial_centers.findIndex(c => c.cancer_center.id === id);
            if (index !== -1) {
              if (selected) trial_centers[index].selected = true;
              if (trial_centers[index].results.findIndex(r => r.trial.id === trial_id) === -1) {
                trial_centers[index].results.push(result);
              }
            } else {
              trial_centers.push({cancer_center, lat, lon, title, selected, results: [result]});
            }
          }
        }
      }

      // Apply the filters

      // Filter centers by locations
      if (query.locations.country_code) {
        trial_centers = trial_centers.filter(({cancer_center}) => {
          if (cancer_center.country !== query.locations.country_code) return false;
          if (query.locations.country_code === 'US' && !query.locations.states[cancer_center.state]) return false;
          return true;
        });
      }

      if (hide_non_us) {
        trial_centers = trial_centers.filter(c => c.cancer_center.country === 'US');
      }

      if (hide_duplicated_trials && all_locations.length > 0) {
        let closest_cancer_centers = [];
        for (let location of all_locations) {
          if (location.lon && location.lat) {
            let closest_cancer_center_distance = null;
            let closest_cancer_center = null;

            for (let center of trial_centers) {
              let distance = center.cancer_center._distances[location.id];
              if (distance < closest_cancer_center_distance || (closest_cancer_center_distance === null)) {
                closest_cancer_center_distance = distance;
                closest_cancer_center = center;
              }
            }


            // Check the closest cancer center is not already added because it was the closest cancer center to another patient location
            if (closest_cancer_center && (closest_cancer_centers.findIndex(c => c.cancer_center.id === closest_cancer_center.cancer_center.id) === -1) ) {
              closest_cancer_centers.push(closest_cancer_center);
            }
          }
        }

        trial_centers = closest_cancer_centers;
      }


      // Add the trial centers to the centers list
      for (let center of trial_centers) {
        let index = all_centers.findIndex(c => c.cancer_center.id === center.cancer_center.id);
        if (index !== -1 ) {
          if (center.selected) all_centers[index].selected = true
          all_centers[index].results = all_centers[index].results.concat(center.results);
        } else {
          all_centers.push(center);
        }
      }
    }


    // Create markers for cancer centers
    let markers = []

    for (let center of all_centers) {
      let {cancer_center, lat, lon, title, results} = center;
      let onClick = () => {
        this.setState({selected: center});
      }
      let color = GREY;
      if (center.selected) color= GREEN;
      else if (cancer_center.fake) color = LIGHT_GRAY;

      let style = {color, caption: results.length}
      let data = {cancer_center, results}
      markers.push({data, lat, lon, title, onClick, style, zIndexOffset: 1, grouped: true})
    }


    // Extra locations
    if (extra_locations && extra_locations.length > 0) {
      for (let location of extra_locations) {
        let {lat, lon, display_name} = location;
        let style = {color: BLUE, icon: 'map-marked'}
        markers.push({lat, lon, title: display_name, data: {}, style, zIndexOffset: 1})
      }
    }

    if (patient && patient.locations) {
      let style = {color: BLUE, icon: 'house-user'}
      for (let location of patient.locations) {
        let {lat, lon, primary} = location;
        if (lat && lon) {
          markers.push({excludeSelection: true, lat, lon, style, zIndexOffset: 999})
        }
      }
    }

    return markers;
  }


  updateMakers() {
    let markers = this.buildMarkers(this.props);
    this.setState({markers});
  }


  resizeWindow() {
    let {container} = this.refs;
    let window_height = window.innerHeight;
    let height = window_height - container.offsetTop - MARGIN_BOTTOM;
    this.setState({height});
  }

  renderResult() {
    let {selected, height} = this.state;
    if (!selected) return null;
    let {patient, admin_mode, collections, selection, toggleSelection, addTrialToCollection} = this.props;
    let {cancer_center} = selected;
    return (
      <div id="results-box" style={{maxHeight: height - 14}}>
        <div>
          <h4><b>{cancer_center.name}</b></h4>
        </div>
        {selected.results.map(result => {
          let selected = selection.indexOf(result.trial.id) !== -1
          return <SearchResult {...result} admin_mode={admin_mode} patient={patient} collections={collections} addTrialToCollection={addTrialToCollection} key={result.trial.id} selected={selected} selectCallback={toggleSelection} />
        })}
      </div>
    );
  }


  render() {
    let {markers, height} = this.state;
    let {onMapSelectionChange} = this.props;
    return (
      <div id="results_map" ref="container" style={{height}}>
        {this.renderResult()}
        <MapViewer height={height} markers={markers} selectable={true} onSelectionChange={onMapSelectionChange} onClickOnMap={e => this.setState({selected: null})} />
      </div>
    );
  }
}

const MARGIN_BOTTOM = 80;
