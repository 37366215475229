import React, {Component} from 'react';
import request from '../../request';
import Stadox from '../../stadox';

import {Phases} from '../../constants.js.erb';
import Notifications from '../notifications';
import * as Inflector from 'inflected';

const FILTERS = {
  genes: {title: "Genes", group: true, groupID: 'diagnostics_and_gene_id', groupLabel: 'name', preProcessor: filterDiagnosticsAndGenes, factory: () => DiagnosticsAndGenesSearchFilter, sortCallback: sortByKnownCount},
  diagnostics: {title: "Diagnostics", group: true, groupID: 'diagnostics_and_gene_id', groupLabel: 'name', preProcessor: filterDiagnosticsAndGenes, factory: () => DiagnosticsAndGenesSearchFilter, sortCallback: sortByKnownCount},
  patient_characteristics: {title: "Additional Requirements", group: true, groupID: 'patient_characteristic_id', groupLabel: 'patient_characteristic_name', sortCallback: sortPatientCharacteristics},
  prior_therapy: {title: 'Prior Therapy', group: true, groupID: 'major_class_id', groupLabel: 'major_class_name', factory: () => PriorTherapySearchFilter}
}

export class SearchFilters extends Component {

  constructor(props) {
    super(props);
    this.state = {section_selected: null};
  }

  toggleSection(section) {
    if (section === this.state.section_selected) {
      this.setState({section_selected: null});
    } else {
      this.setState({section_selected: section});
    }
  }


  renderFilter(key) {
    let {query, filters, onChange, onChangeAll, onAddFilter, onSubmit, results, admin_mode} = this.props;
    let {title, group, groupID, groupLabel, sortCallback, factory, visible} = FILTERS[key]

    if (visible && !visible({query, filters})) {
      return null;
    }

    let hideRelevantMessage = key === 'patient_characteristics'
    let show = this.state.section_selected === key;

    let props = {
      query, key, results, title, groupID, groupLabel, admin_mode, show, hideRelevantMessage, onChange: onChange, onChangeAll, onAddFilter, onSubmit, sortCallback,
      section: key,
      filters: filters[key],
      all_filters: filters,
      toggle: s => this.toggleSection(key)
    };

    if (FILTERS[key].preProcessor) {
      props = FILTERS[key].preProcessor(props);
    }

    let clazz;
    if (factory) {
      clazz = factory();
    } else if (group) {
      clazz = GroupedSearchFilter;
    } else {
      clazz = SearchFilter;
    }

    return React.createElement(clazz, props);
  }

  render() {
    return (
      <div id="filters">
        {Object.keys(FILTERS).map(key => this.renderFilter(key))}
      </div>
    )

  }
}



class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {filters: props.filters, sort_field: "name", sort_direction: "asc"};
    this.ctx = Stadox.subscribe(this);
  }

  filterDisabled(filter) {
    return false;
  }

  select(index, value) {
    this.props.onChange(this.props.section, index, value);
  }

  selectAll(value, value_label) {
    if (window.confirm("You are about to mark all as " +  value_label +". Are you sure you want to do that?"))
    this.props.onChangeAll(this.props.section, value);
  }


  sortBy(field) {
    let {sort_field, sort_direction, filters} = this.state;

    if (sort_field === field) {
      sort_direction = sort_direction === "asc" ? "desc" : "asc";
    } else {
      sort_direction = "asc";
    }

    let sorted_filters = filters.sort((a, b) => {
      let field_a, field_b;
      if (field === "name") {
        field_a = a.item.label;
        field_b = b.item.label;
      } else if (field === "affected") {
        field_a = a.affected;
        field_b = b.affected;
      }


      if (sort_direction === "asc") {
        if (field_a > field_b) return 1;
        if (field_a < field_b) return -1;
      } else {
        if (field_a < field_b) return 1;
        if (field_a > field_b) return -1;
      }
      return 0;
    });

    this.setState({sort_field: field, sort_direction, filters: sorted_filters});

  }

  renderSortColumn(field, label) {
    let caret;
    let {sort_field, sort_direction} = this.state;
    if (sort_field === field) {
      let caret_icon = sort_direction === "asc" ? "fa-caret-up" : "fa-caret-down"
      caret = <i className={"fa " + caret_icon} />
    }


    return (
      <button className="btn btn-link" onClick={e => this.sortBy(field)}>
        {label} {caret}
      </button>
    )
  }

  popoverContent(data) {
    let {results} = this.props;
    let content = []

    for (let trial_id of data.affected_trials) {
      let d = results.find(r => r.trial.id === trial_id);
      if (d) {
        content.push("<li>" + d.trial.identifier + ": " + d.trial.name + "</li>")
      }
    };

    let output = "<h2 class=\"text-center\">Trials</h2><ul>" + content.join("\n") + "</ul>";
    return output;
  }

  initTooltip(e, data) {
    if (!e) return;
    if (!data.affected || !data.affected_trials) return;

    let popover = $(e).popover({html: true, content: e => this.popoverContent(data)});
    popover.on('show.bs.popover', e => {
      if (this.current_popover) {
        this.current_popover.popover('hide');
      }
      this.current_popover = popover;
    });
    popover.on('hide.bs.popover', e => {
      this.current_popover = null;
    });

  }

  renderAffected(data) {
    if (!data.affected || !data.affected_trials) {
      if (this.props.hideRelevantMessage) return null;
      return <span className="populations_affected_count no_data">[not relevant to results]</span>;
    }
    return (
      <span className="populations_affected_count clickable" ref={e => this.initTooltip(e, data)}>[{data.affected}]</span>
    )
  }



  renderOptionsBody() {
    let {section} = this.props;
    let data = this.state.filters;
    let options = [];


    for (let i=0; i < data.length; i++) {
      let c = data[i].item;
      let name = section + "_" + c.id;

      options.push(
        <tr key={c.id}>
          <td>{c.label} {this.renderAffected(data[i])}</td>

          <td style={{width: "30px"}}><input type="radio" checked={data[i].selected == 1} name={name} onChange={e => this.select(i, 1)} /></td>
          <td style={{width: "30px"}}><input type="radio" checked={data[i].selected == -1} name={name} onChange={e => this.select(i, -1)} /></td>
          <td style={{width: "30px"}}><input type="radio" checked={data[i].selected == 0} name={name} onChange={e => this.select(i, 0)} /></td>
        </tr>
      )
    }
    return options;
  }

  copyToClipboard() {
    let data = this.state.filters;
    let content = data.map(d => d.item.name || d.item.label).join(', ');
    navigator.clipboard.writeText(content);
    this.setState({copied_to_clipboard: true});
    let callback = () => this.setState({copied_to_clipboard: false});
    setTimeout(callback, 2500)
  }

  renderCopyButton() {
    let data = this.state.filters;
    if (!data || data.length === 0) return null;
    if (!navigator || !navigator.clipboard) return null;

    let {copied_to_clipboard} = this.state;
    let label, style, onClick;

    if (copied_to_clipboard) {
      label = "Copied to Clipboard";
      style = "btn-success";
    } else {
      label = "Copy to Clipboard";
      style = "btn-default";
      onClick = e => this.copyToClipboard();
    }

    let className = "btn btn-sm pull-right " + style;
    return (
      <button className={className} onClick={onClick}>{label}</button>
    )
  }

  renderSortHeaders() {
    return (
      <th className="sort_links">
        Order by:
        {this.renderSortColumn("name", "Name")}
        {this.renderSortColumn("affected", "Affected")}
        {this.renderCopyButton()}
      </th>
    );
  }

  renderOptions() {
    let {show, onSubmit} = this.props;
    if (!show) return null;

    return (
      <div className="panel-body">
        <table style={{width: "100%"}} className="relevant_filter_table">
          <thead>
            <tr>
              {this.renderSortHeaders()}
              <th style={{width: "30px"}}><button className="btn btn-link btn-block" onClick={e => this.selectAll(1, "Yes")}>Yes</button></th>
              <th style={{width: "30px"}}><button className="btn btn-link btn-block" onClick={e => this.selectAll( -1, "No")}>No</button></th>
              <th style={{width: "30px"}}><button className="btn btn-link btn-block" onClick={e => this.selectAll(0, "Unknown")}>?</button></th>
            </tr>
          </thead>

          <tbody>
            {this.renderOptionsBody()}
          </tbody>
        </table>
        <div className="filter_actions">
          <button onClick={onSubmit} className="btn btn-success pull-right">Apply Changes</button>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }

  render() {
    let data = this.state.filters;
    if (!data) return null;

    let {section, title, show, onSubmit, toggle} = this.props;

    let relevant_count = data.filter(d => d.affected > 0).length;;
    let known_count = data.filter(d => d.selected !== 0).length;

    return(
      <div className="panel panel-default panel-toggle">
        <div className="panel-heading" onClick={toggle}>
          <p className="pull-right">{relevant_count} Relevant | {known_count} Known</p>
          <h3 className="panel-title">{title}</h3>
        </div>
        {this.renderOptions()}
      </div>
    );
  }
}


class GroupedSearchFilter extends SearchFilter {
  static getDerivedStateFromProps(props, state) {
    let {results, filters, groupID, groupLabel, sortCallback} = props;
    if (!filters) return state;

    if (state.selected_groups === undefined) {
      state.selected_groups = {};
    }

    state.grouped_filters = filters.reduce(
      (accumulator, currentValue) => {
        let key = currentValue.item[groupID];
        if (key === undefined) return accumulator;
        if (currentValue.auto && !currentValue.affected) return accumulator;
        if (!accumulator[key]) accumulator[key] = [];
        accumulator[key].push(currentValue);
        return accumulator;
      }
      ,{}
    );


    let grouped_filter_ids = Object.keys(state.grouped_filters);
    if (sortCallback) {
      grouped_filter_ids = sortCallback(grouped_filter_ids, state.grouped_filters, groupID, groupLabel);
    }

    state.filters_data = grouped_filter_ids.map(id => {
      let filter = filters.find(r => +r.item[groupID] === +id); // Force casting
      return {id, label: filter.item[groupLabel]};
    });

    return state;
  }

  toggleGroupVisibility(id) {
    let {selected_groups} = this.state;
    selected_groups[id] = !selected_groups[id];
    this.setState({selected_groups});
  }

  renderSortHeaders() {
    return (
      <th className="sort_links">
      </th>
    );
  }


  renderHeader(filter_data, grouped_filters) {
    let count = grouped_filters[filter_data.id].length;
    let relevant_count = grouped_filters[filter_data.id].filter(d => d.affected > 0).length;;
    let known_count = grouped_filters[filter_data.id].filter(d => d.selected !== 0).length;

    return (
      <tr key={"group_" + filter_data.id}>
        <td>
          <button className="btn btn-link relevant_filter_group" onClick={e => this.toggleGroupVisibility(filter_data.id)}><b>{filter_data.label} ({count})</b></button>
          <p className="pull-right relevant_and_known_counts">{relevant_count} Relevant &bull; {known_count} Known</p>
        </td>
        <td style={{width: "30px"}}></td>
        <td style={{width: "30px"}}></td>
        <td style={{width: "30px"}}></td>
      </tr>
    );
  }

  renderOptionsBody() {
    let {section} = this.props;
    let {grouped_filters, filters_data, selected_groups} = this.state;

    let rows = [];
    for (let filter_data of filters_data) {
      rows.push(this.renderHeader(filter_data, grouped_filters));

      if (selected_groups[filter_data.id]) {
        for (let filter of grouped_filters[filter_data.id]) {
          let index = this.findFilterIndex(filter);
          let c = filter.item;
          let name = section + "_" + c.type + '_' + c.id;

          let disabled = this.filterDisabled(filter);
          let rowClass = disabled ? 'disabled' : '';
          rows.push(
            <tr key={c.type + '_' + c.id} className={rowClass}>
              <td>{c.label} {this.renderAffected(filter)}</td>
              <td style={{width: "30px"}}><input type="radio" checked={filter.selected == 1} name={name} disabled={disabled} onChange={e => this.select(index, 1, filter_data)} /></td>
              <td style={{width: "30px"}}><input type="radio" checked={filter.selected == -1} name={name} disabled={disabled} onChange={e => this.select(index, -1, filter_data)} /></td>
              <td style={{width: "30px"}}><input type="radio" checked={filter.selected == 0} name={name} disabled={disabled} onChange={e => this.select(index, 0, filter_data)} /></td>
            </tr>
          )
        }
      }
    }

    return rows;
  }

  findFilterIndex(filter) {
    let {section, filters} = this.props;
    let index = filters.findIndex(f => f.item.type === filter.item.type && f.item.id === filter.item.id);
    return index;
  }
}



/**
 * Search filter for diagnsostics and gene
 * Select automatically the otions ANY when user selects any other
 * option.
 */
class DiagnosticsAndGenesSearchFilter extends GroupedSearchFilter {
  findFilterIndex(filter) {
    return filter.index;
  }


  select(index, value, filter_data) {
    super.select(index, value);
    if (value !== 1) return;

    let {all_filters, onChange} = this.props;
    let {filters} = this.state;

    let filter = filters[index];
    if (filter.item.any) return;
    let diagnostics_and_gene_id = filter.item.diagnostics_and_gene_id;

    let changed = false;
    for(let i=0;i < filters.length; i++ ) {
      let f = filters[i];
      if (i !== index && f.item.diagnostics_and_gene_id === diagnostics_and_gene_id && f.item.any &&!f.selected) {
        super.select(i, 1);
        changed = true;
      }
    }

    if (changed) {
      Notifications.push("ANY option selected automatically");
    }
  }
}


/**
 * Filter for prior therapies.
 * Group items by major class and then by record type.
 * When the admin selects YES an item within a certain Major Class
 * panel, the Major Class is also marked as YES. This is good and
 * working well.
 */

const PRIOR_THERAPY_SECTION = 'prior_therapy';
const PRIOR_THERAPY_MAJOR_CLASSES_SECTION = 'prior_therapy_major_classes';

class PriorTherapySearchFilter extends GroupedSearchFilter {
  static getDerivedStateFromProps(props, state) {
    let {results, all_filters, filters, groupID, groupLabel} = props;
    if (!filters) return state;

    if (state.selected_groups === undefined) {
      state.selected_groups = {};
    }

    state.grouped_filters = {};
    state.filters_data = []

    for (let major_class of all_filters[PRIOR_THERAPY_MAJOR_CLASSES_SECTION]){
      let  {id, name} = major_class.item;
      state.grouped_filters[id]= []
      state.filters_data.push({id, label: name});
    }

    for (let filter of filters) {
      if (filter.auto && !filter.affected) break;
      state.grouped_filters[filter.item[groupID]].push(filter);
    }

    return state;
  }

  select(index, value, filter_data) {
    super.select(index, value);
    if (value !== 1) return;

    let {all_filters, onChange} = this.props;
    let {filters} = this.state;
    let major_class_id = +filter_data.id;

    let major_class_index = all_filters[PRIOR_THERAPY_MAJOR_CLASSES_SECTION].findIndex(f => f.item.id === major_class_id);
    let major_class_filter = all_filters[PRIOR_THERAPY_MAJOR_CLASSES_SECTION][major_class_index];
    let filter_value =  major_class_filter ? major_class_filter.selected : 0;

    if (filter_value === 0) {
      onChange(PRIOR_THERAPY_MAJOR_CLASSES_SECTION, major_class_index, 1);
      Notifications.push("Major class selected automatically");
    }


    // Fetch dependent filters
    let filter = filters[index];
    this.fetchRelatedFilters(filter.item);
  }

  changeSubgroup(subgroup, value, filter_data) {
    let {all_filters, onChange} = this.props;
    for (let filter of subgroup) {
      let index = all_filters[PRIOR_THERAPY_SECTION].findIndex(f => f.item.id === filter.item.id);
      this.select(index, value, filter_data);
    }
  }


  fetchRelatedFilters(item) {
    let {admin_mode, all_filters, onChange, onAddFilter} = this.props;
    let url = `/search/related_filters?item_type=${item.type}&item_id=${item.id}`;
    if (admin_mode) {
      url = '/admin' + url;
    }

    request('GET', url).then(filters => {
      let prior_therapy_filters = all_filters[PRIOR_THERAPY_SECTION];
      let changed = false;
      for (let filter of filters) {
        let index = prior_therapy_filters.findIndex(f => f.item.type === filter.item.type && f.item.id === filter.item.id);
        if (index !== -1) {
          if (!prior_therapy_filters[index].selected) {
            onChange(PRIOR_THERAPY_SECTION, index, 1);
            changed = true;
          }
        } else {
          filter.selected = 1;
          onAddFilter(PRIOR_THERAPY_SECTION, filter);
          changed = true;
        }
      }

      if (changed) Notifications.push("Prior Therapy Filters changed");
    })
  }


  changeMajorClass(major_class_id, value) {
    let {all_filters, onChange} = this.props;
    let major_class_index = all_filters[PRIOR_THERAPY_MAJOR_CLASSES_SECTION].findIndex(f => f.item.id === major_class_id);
    onChange(PRIOR_THERAPY_MAJOR_CLASSES_SECTION ,major_class_index, value);
  }


  renderPriorThreapyDisabled() {
    return (
      <div className="prior_therapy_disabled text-center">
        <h4>Set as Newly Diagnosed</h4>
        <p><i className="fa fa-info-circle"></i> If the patient has prior therapies, update in Patient Details panel.</p>
      </div>
    )
  }

  renderOptions() {
    let {show, all_filters} = this.props;
    if (!show) return null;
    if (all_filters.patient_details.had_prior_therapy === false) {
      return this.renderPriorThreapyDisabled();
    }

    return super.renderOptions();
  }

  renderHeader(filter_data, grouped_filters) {
    let {groupID, all_filters, onChange} = this.props;
    let {filters} = this.state;
    let name = "grouped::major_classes" + filter_data.id;
    let major_class_id = +filter_data.id;

    let major_class_index = all_filters[PRIOR_THERAPY_MAJOR_CLASSES_SECTION].findIndex(f => f.item.id === major_class_id);


    let major_class_filter = all_filters[PRIOR_THERAPY_MAJOR_CLASSES_SECTION][major_class_index];
    let filter_value =  major_class_filter ? major_class_filter.selected : 0;

    let count = grouped_filters[filter_data.id].length;
    let relevant_count = grouped_filters[filter_data.id].filter(d => d.affected > 0).length;;
    let known_count = grouped_filters[filter_data.id].filter(d => d.selected !== 0).length;

    // Hide groups not configured
    if (filter_value === 0 && count === 0) {
      return null;
    }

    return (
      <tr key={"group_" + filter_data.id}>
        <td>
          <button className="btn btn-link relevant_filter_group" onClick={e => this.toggleGroupVisibility(filter_data.id)}><b>{filter_data.label} ({count})</b></button>
          <p className="pull-right relevant_and_known_counts">{relevant_count} &bull; {known_count} Known</p>
        </td>
        <td style={{width: "30px"}}><input type="radio" checked={filter_value == 1} name={name} onChange={e => this.changeMajorClass(major_class_id, 1)} /></td>
        <td style={{width: "30px"}}><input type="radio" checked={filter_value == -1} name={name} onChange={e => this.changeMajorClass(major_class_id, -1)} /></td>
        <td style={{width: "30px"}}><input type="radio" checked={filter_value == 0} name={name} onChange={e => this.changeMajorClass(major_class_id, 0)} /></td>
      </tr>
    );
 }

  renderOptionsBody() {
    let {section, filters, all_filters} = this.props;
    let {grouped_filters, filters_data, selected_groups} = this.state;

    let rows = [];
    for (let filter_data of filters_data) {
      rows.push(this.renderHeader(filter_data, grouped_filters));

      if (selected_groups[filter_data.id]) {

        let subgroups = {}
        for (let filter of grouped_filters[filter_data.id]) {
          if (!subgroups[filter.item.type]) subgroups[filter.item.type] = [];
          subgroups[filter.item.type].push(filter);
        }


        for (let type of Object.keys(subgroups)) {
          let subgroup = subgroups[type];
          let subgroup_title = Inflector.titleize(type);
          let subgroup_count = subgroup.length;
          rows.push(
            <tr key={"subgroup" + filter_data.id + type }>
              <td><strong>{subgroup_title} ({subgroup_count})</strong></td>
              <td style={{width: "30px"}}><button className="btn btn-link" onClick={e => this.changeSubgroup(subgroup, 1, filter_data)}>Yes</button></td>
              <td style={{width: "30px"}}><button className="btn btn-link" onClick={e => this.changeSubgroup(subgroup, -1, filter_data)}>No</button></td>
              <td style={{width: "30px"}}><button className="btn btn-link" onClick={e => this.changeSubgroup(subgroup, 0, filter_data)}>?</button></td>
            </tr>
          )


          for (let filter of subgroups[type]) {
            let index = filters.findIndex(f => f.item.type === filter.item.type && f.item.id === filter.item.id);
            let c = filter.item;
            let name = section + "_" + c.type + '_' + c.id;

            rows.push(
              <tr key={c.type + '_' + c.id}>
                <td>{c.label} {this.renderAffected(filter)}</td>
                <td style={{width: "30px"}}><input type="radio" checked={filter.selected == 1} name={name} onChange={e => this.select(index, 1, filter_data)} /></td>
                <td style={{width: "30px"}}><input type="radio" checked={filter.selected == -1} name={name} onChange={e => this.select(index, -1, filter_data)} /></td>
                <td style={{width: "30px"}}><input type="radio" checked={filter.selected == 0} name={name} onChange={e => this.select(index, 0, filter_data)} /></td>
              </tr>
            )
          }
        }
      }
    }

    return rows;
  }
}


export class QueryFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  toggle() {
    this.setState({open: !this.state.open});
  }

  changeFilter(section, e, isCheckbox) {
    let {name, value, checked} = e.target;
    let {onChange, filters} = this.props
    if (!filters[section]) filters[section] = {};
    let field_value = isCheckbox ? checked : value;
    filters[section][name] = field_value;
    onChange && onChange({filters});
  }

  changeQueryCheckbox(section, e) {
    let {name, checked} = e.target;
    let {onChange, query} = this.props
    if (!query[section]) query[section] = {};
    query[section][name] = checked;
    onChange && onChange({query});
  }


  changeTrialFilters(e) {
    let {name, value} = e.target;
    let {onChange, query} = this.props
    if (!query.trial_filters) query.trial_filters = {};
    query.trial_filters[name] = value;
    onChange && onChange({query});
  }

  renderTrialFilters() {
    let {query} = this.props;
    let filters = query.trial_filters || {};

    return (
      <table style={{width: "100%"}} className="filters_table">
        <thead>
          <tr>
            <th style={{width: "30px"}}>Yes</th>
            <th style={{width: "30px"}}>No</th>
            <th style={{width: "30px"}}>?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <CheckboxSelector name="included_combination" value={filters.included_combination} onChange={e => this.changeTrialFilters(e) }>
            Combination <i className="fa fa-columns pull-right" />
          </CheckboxSelector>

          <CheckboxSelector name="references" value={filters.references} onChange={e => this.changeTrialFilters(e) }>
            References <i className="fa fa-book pull-right" />
          </CheckboxSelector>

          <CheckboxSelector name="arm_randomized" value={filters.arm_randomized} onChange={e => this.changeTrialFilters(e) }>
            Randomizaion <i className="fa fa-random pull-right" />
          </CheckboxSelector>

          <CheckboxSelector name="includes_placebo" value={filters.includes_placebo} onChange={e => this.changeTrialFilters(e) }>
             Placebo <i className="fa fa-exclamation-circle pull-right" />
          </CheckboxSelector>
        </tbody>
      </table>

    )
  }


  renderMajorClasses() {
    let filters = this.props.filters.major_classes || {};
    let content = gon.majorClasses.map(({id, name}) => {
      let element_id = "major_class_" + id;
      return (
        <ExtendedCheckboxSelector id={element_id} key={id} name={id} value={filters[id]} onChange={e => this.changeFilter("major_classes", e) }>
          {name}
        </ExtendedCheckboxSelector>
      )
    });

    return (
      <div>
        <h4>Major Classes</h4>
        <table style={{width: "100%"}} className="filters_table">
          <thead>
            <tr>
              <th style={{width: "45px"}} title="Must include this class">Yes</th>
              <th style={{width: "45px"}} title="Cannot have this class ">None</th>
              <th style={{width: "45px"}} title="Do not include unless other classes are present">No</th>
              <th style={{width: "45px"}} title="No filtering based on this class">?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {content}
          </tbody>
        </table>
      </div>
    )
  }

  renderROAFilters() {
    let filters = this.props.filters.route_of_administration || {};

    return (
      <div>
        <h4>Route Of Administration</h4>
        <table style={{width: "100%"}} className="filters_table">
          <thead>
            <tr>
              <th style={{width: "30px"}}>Yes</th>
              <th style={{width: "30px"}}>No</th>
              <th style={{width: "30px"}}>?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {gon.routeOfAdministration.map(roa => {
              return (
                <CheckboxSelector key={roa.id} name={roa.id} value={filters[roa.id]} onChange={e => this.changeFilter('route_of_administration', e)}>
                  {roa.name}
                </CheckboxSelector>
              )})}
          </tbody>
        </table>
      </div>
    )
  }

  renderTherapyStatus() {
    let {query} = this.props;
    let therapy_status = query.therapy_status;

    let content = gon.therapyStatus.map(({key, label}) => {
      return (
        <div key={"status_" + key}>
          <input type="checkbox" name={key} checked={therapy_status[key] || ""} value={therapy_status[key] || ""} onChange={e => this.changeQueryCheckbox("therapy_status", e)}/> <label>{label}</label>
        </div>
      );
    })

    return (
      <div className="filter_list_holder">
        <p className="filter_list_header">Curation Status</p>
        {content}
        <input type="checkbox" name="_empty" checked={therapy_status._empty || ""} value={therapy_status._empty || ""} onChange={e => this.changeQueryCheckbox("therapy_status", e)} />
        <label><i>No Status</i></label>
      </div>
    );
  }

  renderPhases() {
    let {query} = this.props;
    let therapy_phases = query.therapy_phases || {};

    let content = Object.keys(Phases).map((phase_id) => {
      let label = Phases[phase_id];
      return (
        <div key={phase_id}>
          <input type="checkbox" name={phase_id} checked={therapy_phases[phase_id] || ""} value={therapy_phases[phase_id] || ""} onChange={e => this.changeQueryCheckbox("therapy_phases", e)}/> <label>{label}</label>
        </div>
      );
    })

    return (
      <div className="filter_list_holder">
        <p className="filter_list_header">Phase</p>
        {content}
        <input type="checkbox" name="_empty" checked={therapy_phases._empty || ""} value={therapy_phases._empty || ""} onChange={e => this.changeQueryCheckbox("therapy_phases", e)} />
        <label><i>Nothing Provided</i></label>
      </div>
    );
  }

  renderLocationsCount() {
    let {query} = this.props;
    let therapy_locations_count = query.therapy_locations_count || {};

    return (
      <div className="filter_list_holder">
        <p className="filter_list_header">Locations Count</p>
        {LOCATIONS_COUNT_FILTERS.map(({id, label}) => {
          let checked = therapy_locations_count[id] || false;
          return (
            <div key={id}>
              <input type="checkbox" name={id} checked={checked} value={checked} onChange={e => this.changeQueryCheckbox("therapy_locations_count", e)} />
              <label>{label}</label>
            </div>
          );
        })}
      </div>
    );
  }


  renderFDAApprovedAnyCancer() {
    let filters = this.props.filters.groups || {};
    return (
      <div className="filter_list_holder">
        <p className="filter_list_header">Groups</p>
        <input type="checkbox" name="fda_approved_any_cancer" value={filters.fda_approved_any_cancer} checked={filters.fda_approved_any_cancer} onChange={e => this.changeFilter("groups", e,  true) } />
        <label>Limit results to only FDA approved interventions</label>
      </div>

    )
  }

  renderContent() {
    let {open} = this.state;
    if (!open) return null;
    return (
      <div className="panel-body row">
        <div className="col-md-6">
          {this.renderTrialFilters()}
          {this.renderMajorClasses()}
          {this.renderROAFilters()}
        </div>

        <div className="col-md-6">
          {this.renderTherapyStatus()}
          {this.renderPhases()}
          {this.renderLocationsCount()}
          {this.renderFDAApprovedAnyCancer()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="query_filters" className="panel panel-default panel-toggle">
        <div className="panel-heading" onClick={e => this.toggle()}>
          <h3 className="panel-title">Filters</h3>
        </div>
        {this.renderContent()}
      </div>
    );
  }

}


class CheckboxSelector extends Component {
  setValue(name, value) {
    let {onChange} = this.props;
    let event = {target: {name, value}};
    onChange && onChange(event);
  }

  render() {
    let {element_id, value, name, children} = this.props;
    let id = element_id || name;
    return (
      <tr>
        <td><input type="radio" value={value === true} checked={value === true} name={element_id} onChange={e => this.setValue(name, true)} /></td>
        <td><input type="radio" value={value === false} checked={value === false} name={element_id} onChange={e => this.setValue(name, false)} /></td>
        <td><input type="radio" value={value === undefined}  checked={value === undefined} name={element_id} onChange={e => this.setValue(name, undefined)} /></td>
        <td>{children}</td>
      </tr>
    )
  }
}


class ExtendedCheckboxSelector extends Component {
  setValue(name, value) {
    let {onChange} = this.props;
    let event = {target: {name, value}};
    onChange && onChange(event);
  }

  render() {
    let {element_id, value, name, children} = this.props;
    let id = element_id || name;
    return (
      <tr>
        <td><input type="radio" value={value === true} checked={value === true} name={element_id} onChange={e => this.setValue(name, true)} /></td>
        <td><input type="radio" value={value === false} checked={value === false} name={element_id} onChange={e => this.setValue(name, false)} /></td>
        <td><input type="radio" value={value === 'norex'}  checked={value === 'norex'} name={element_id} onChange={e => this.setValue(name, 'norex')} /></td>
        <td><input type="radio" value={value === undefined}  checked={value === undefined} name={element_id} onChange={e => this.setValue(name, undefined)} /></td>
        <td>{children}</td>
      </tr>
    )
  }
}


const LOCATIONS_COUNT_FILTERS = [
  {id: '1_location', label: '1 location'},
  {id: '2_10_locations', label: '2-10 locations'},
  {id: '10_or_more', label: 'More than 10 locations'}
];

function filterDiagnosticsAndGenes(props) {
  let {filters} = props;
  // first hardcode the index
  for (let i=0; i < filters.length; i++) {
    filters[i].index = i;
  }

  let cancer_type_diagnostics_and_genes = Stadox.get('cancer_type_diagnostics_and_genes');
  if (cancer_type_diagnostics_and_genes  && cancer_type_diagnostics_and_genes.length > 0) {
    props.filters= filters.filter(filter => {
      return cancer_type_diagnostics_and_genes.findIndex(d => d.id === filter.item.diagnostics_and_gene_id) === -1
    });
  }
  return props;
}


function sortPatientCharacteristics(ids, data) {
  return ids.sort(function(id_a, id_b){

    let priority_a = data[id_a][0].item.patient_characteristic_priority;
    let priority_b = data[id_b][0].item.patient_characteristic_priority;

    if (priority_a !== priority_b) {
      return priority_b - priority_a;
    } else {
      let name_a = data[id_a][0].item.patient_characteristic_name;
      let name_b = data[id_b][0].item.patient_characteristic_name;
      return ('' + name_a).localeCompare(name_b);
    }
  })
}


function sortByKnownCount(ids, data, groupID, groupLabel) {
  return ids.sort(function(id_a, id_b){
    let relevant_a = data[id_a].filter(d => d.affected > 0).length > 0;
    let relevant_b = data[id_b].filter(d => d.affected > 0).length > 0;

    if (relevant_a === relevant_b) {
      let name_a = data[id_a][0].item[groupLabel];
      let name_b = data[id_b][0].item[groupLabel];
      return ('' + name_a).localeCompare(name_b);
    } else if (relevant_a) {
      return -1;
    } else {
      return 1
    }
    return 0;
  })
}
